/** Represents the environment in which the application will be running
 * - `ganache`: Used to local testing, in which case you should also change the contract addresses (see `addresses` and `getReactionsAddress`)
 * - `goerli_testnet`: Public environment for the Devcon event
 * - `goerli_optimism`: Public environment
 */
export const ENVIRONMENT: Env = "goerli_testnet";

/** Type for the specific possible environments */
export type Env = "ganache" | "goerli_testnet" | "goerli_optimism";

// This is the chainId your dApp will work on.
export const selectNetwork = () => {
  switch (ENVIRONMENT as Env) {
    case "ganache":
      return 1337;
    case "goerli_optimism":
      return 420;
    case "goerli_testnet":
      return 5;
    default:
      return 5;
  }
};
/** Defines which guild addresses the user should connect to, depending on the environment */
export const addresses: Record<Env, string[]> = {
  ganache: [
    "0xc8d70E413Fa1a6949AF62346Ff4f95E92066ae5D",
    "0x097bC64aF0A26A851A52A34D426A6f885B20Db22",
    "0xA3bdCe3175a6f78a18e37f5b4EFe342629581c98",
    /* "0xc8d70E413Fa1a6949AF62346Ff5f95E92066ae5D", */
    /* "0x914EcB9cF500fD2565362c1b0893aaAEF5BBE4dF", */
  ],
  goerli_testnet: [
    /* legal tech "0x1381E8462c1f2c27D559D2c5F107a6AE31634e3f",*/
    /* Eth Musicians v0, "0xa3345bA8CFe5aC6D3B91f8Fb99b9611360e8B48b"*/
    /* Eth Musicians v1*/ "0x25847044E72Dbd58F70f579F987C97685bCa9253",
  ],
  goerli_optimism: [
    /*ETH musicians*/ "0x7f9cc01C6f822aBF8e5134C7CD1d372212bc5F4e",
  ],
};
/** Returns the address for the reactions contract (devcon functionality) */
export const getReactionsAddress = (): string => {
  switch (ENVIRONMENT as Env) {
    case "ganache":
      return "0x33223F6AB29f41E5eD0F20a2B8c5f5a5fdB026bb";
    case "goerli_testnet":
      return "0x8f121842923E68Cc1C84524E3d63a054BCA35337";
    default:
      return "";
  }
};

export type AwsEndpoints = {
  get: string;
  post: string;
  url: string;
};
/** Returns the aws endpoint URLs depending on current environment */
export const getAwsEndpoints = (): AwsEndpoints => {
  switch (ENVIRONMENT as Env) {
    case "ganache":
      return {
        get: "https://rqezr5p7mj.execute-api.eu-west-1.amazonaws.com/dev/auth/request",
        post: "https://rqezr5p7mj.execute-api.eu-west-1.amazonaws.com/dev/auth/confirm",
        url: "https://d2gwhnsa36vxjg.cloudfront.net",
      } as AwsEndpoints;
    case "goerli_testnet":
      return {
        get: "https://eiz6rgm4rc.execute-api.eu-west-1.amazonaws.com/devcon/auth/request",
        post: "https://eiz6rgm4rc.execute-api.eu-west-1.amazonaws.com/devcon/auth/confirm",
        url: "https://d20mgpq1twzc7p.cloudfront.net",
      } as AwsEndpoints;
    default:
      return {
        get: "https://rqezr5p7mj.execute-api.eu-west-1.amazonaws.com/dev/auth/request",
        post: "https://rqezr5p7mj.execute-api.eu-west-1.amazonaws.com/dev/auth/confirm",
        url: "https://d2gwhnsa36vxjg.cloudfront.net",
      } as AwsEndpoints;
  }
};
/** Addresses to append listeners to, these guild will also show in the Guilds and Explore pages */
export const GUILDS_ADDRESSES: string[] = addresses[ENVIRONMENT];
