import { ethers } from "ethers";
import { store } from "../index";
import { actions as explorerActions } from "../store/slices/explorer";
import tcr from "../abis/Tcr.json";
import token from "../abis/Token.json";
import reactions from "../abis/Reactions.json";
import { ThirdwebSDK } from "@thirdweb-dev/sdk";
import API_KEY from "./API_KEY.json";
import { setEvent } from "./ethersApi";
import { ENVIRONMENT, Env } from "./environment";

// A workaround while actual types gets implemented for Metamask's ethereum

export let provider: ThirdwebSDK;

/** The signer (user) will be undefined if the user is not connected */
export let signer: ethers.Signer | undefined;
export const setSigner = (newSigner: ethers.Signer | undefined) => {
  signer = newSigner;
};
/** List of the user accounts (mainly used for Metamask) */
export let accounts: string[];

/** Returns a read-only provider for whenever the user doesn't have a signer */
export async function getDefaultProvider(environment: Env) {
  const accs = await getAccounts();
  switch (environment) {
    case "ganache":
      if (hasInjectedProvider() && accs.length > 0) {
        const provLocal = new ethers.providers.Web3Provider(
          // @ts-ignore
          window.ethereum,
          "any"
        );
        signer = provLocal.getSigner();
        return new ThirdwebSDK(provLocal);
      }

      const provLocal = new ethers.providers.WebSocketProvider(
        "ws://127.0.0.1:7545"
      );
      return new ThirdwebSDK(provLocal);
    // return new ethers.providers.WebSocketProvider("wss://goerli-light.eth.linkpool.io/ws");
    case "goerli_testnet":
      const prov_goerli_testnet = new ethers.providers.WebSocketProvider(
        "wss://goerli.infura.io/ws/v3/" + API_KEY.infura,
        "goerli"
      );
      return new ThirdwebSDK(prov_goerli_testnet);
    case "goerli_optimism":
      const prov_optimism = new ethers.providers.WebSocketProvider(
        "https://optimism-goerli.infura.io/v3/" + API_KEY.infura,
        "goerli"
      );
      return new ThirdwebSDK(prov_optimism);
    default:
      throw new Error("There isn't a Provider for the selected environment");
  }
}

export const expectedChainId = (env: Env) => {
  switch (env) {
    case "ganache":
      return 1337;
    case "goerli_optimism":
      return 420;
    case "goerli_testnet":
      return 5;
  }
};
/** Gets info about the user and sets the listeners for the provider */
export const initialize = async (
  setProvider: ThirdwebSDK | undefined,
  setSigner: ethers.Signer | undefined,
  SDKChainId: number | undefined
) => {
  console.log("Initializing user/provider");
  const status = {
    hasInjectedProvider: hasInjectedProvider(),
    web3Provider: false,
    address: "",
    networkMismatch: false as
      | false
      | { currentChain: number; desiredChain: number },
  };
  // Check the current network and prompt the user to change it
  if (setProvider) {
    console.log(`Setting SDK provider`,setProvider);
    provider = setProvider;
  } else {
    console.log("Getting default provider");
    provider = await getDefaultProvider(ENVIRONMENT);
  }
  if (setSigner) {
    console.log(`Setting signer`,setSigner);
    signer = setSigner;
    status.address = await signer.getAddress();
    status.web3Provider = true;
  }
  const { chainId } = await provider.getProvider().getNetwork();
  console.log(`ChainId ${chainId}`);
  const currentChain = SDKChainId ? SDKChainId : chainId;
  const desiredChain = expectedChainId(ENVIRONMENT);
  if (desiredChain !== currentChain) {
    console.warn("Network mismatch");
    status.networkMismatch = { desiredChain, currentChain };
    return status;
  }
  return status;
};

/** Checks whether the global ethereum variable has been injected by Metamask */
const hasInjectedProvider = (): boolean => !!window.ethereum;

/** Returns a list of accounts from the provider */

const getAccounts = async (): Promise<string[]> => {
  let accs: string[] = [];
  try {
    //@ts-ignore
    accs = window.ethereum.request({
      method: "eth_accounts",
    });
  } catch (e) {
    console.warn(e);
  }
  return accs;
};

export let connectedGuild: ethers.Contract;
export function setConnectedGuild(address: string) {
  connectedGuild = connectTcrContract(address);
}

export let connectedToken: ethers.Contract;
export const setConnectedToken = (address: string) => {
  connectedToken = connectTokenContract(address);
};

export let connectedReacts: ethers.Contract;
export const setConnectedReacts = (address: string) => {
  connectedReacts = connectReactsContract(address);
};

/** Creates a new contracts and connects it to the signer or provider */
export const connectTcrContract = (tcrAddress: string) => {
  const tcrContract = new ethers.Contract(
    tcrAddress,
    tcr.abi,
    provider.getProvider()
  );
  const signerOrProvider = signer || provider.getProvider();
  console.log(
    "Connect tcr contract - getting provider",
    provider.getProvider()
  );
  return tcrContract.connect(signerOrProvider);
};

/** Connects onto a given address of a token contract */
export const connectTokenContract = (tokenAddress: string): ethers.Contract => {
  const tokenContract = new ethers.Contract(
    tokenAddress,
    token.abi,
    provider.getProvider()
  );
  const signerOrProvider = signer || provider.getProvider();
  return tokenContract.connect(signerOrProvider);
};

/** Connects onto a given address of a reactions contract */
export const connectReactsContract = (
  reactsAddress: string
): ethers.Contract => {
  let reactsContract = new ethers.Contract(
    reactsAddress,
    reactions.abi,
    provider.getProvider()
  );
  const signerOrProvider = signer || provider.getProvider();
  const connectedContract = reactsContract.connect(signerOrProvider);
  // Setting event for new upvote
  setEvent(connectedContract, "ReactionAdded", (...eventData) => {
    const [awarded, awarder, award] = eventData;
    const guild = store.getState().guild;
    const member = guild.members.find((member) => member.owner === awarded);

    const txHash = eventData[eventData.length - 1].transactionHash;

    store.dispatch(
      explorerActions.willPushNotification({
        notification: {
          type: "ReactionAdded",
          guildAddress: guild.address,
          memberName: member?.data ? member?.data : awarded,
          award: Number(award),
          awarder: awarder,
        },
        transactionHash: txHash,
      })
    );
  });
  return connectedContract;
};
