import { useState } from "react";
import { Button, Modal, Container, Form } from "react-bootstrap";
import MemberMiniCard from "../cards/MemberMini";
import { useSelector, useDispatch } from "react-redux";
import {
  selectors as guildSelectors,
  actions as guildActions,
} from "../../store/slices/guild";
import { selectors as userSelectors } from "../../store/slices/user";
import Countdown from "../Countdown";
import { fromDecimals, toDecimals } from "../../utils";
import { MemberInfo } from "../cards/Member";
import { MemberDetails } from "../../api/types";
export const Vote = ({
  member,
  memberInfo,
  update,
}: {
  member: MemberDetails;
  memberInfo: MemberInfo;
  update: Function;
}) => {
  const dispatch = useDispatch();
  const token = useSelector(guildSelectors.token);
  const details = useSelector(guildSelectors.details);
  const hasWeb3Provider = useSelector(userSelectors.web3Provider);
  const [show, setShow] = useState(false);
  const [side, setSide] = useState(false);
  const [amount, setAmount] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const userAllowance = useSelector(guildSelectors.userAllowance);
  const userAddress = useSelector(userSelectors.address);
  const memberIsUser = userAddress === member.owner;
  const decimalsDeposit = toDecimals(member.deposit, token.decimals);
  const isMobile = useSelector(userSelectors.isMobile);
  const Btn = () => {
    return (
      <div
        className={`w-100 d-flex justify-content-between align-items-center px-4 pb-3 pt-4 fw-bold text-white ${
          userAddress ? "hover-darken" : ""
        }  rounded-3`}
        role="button"
        onClick={handleShow}
        style={{ height: 60 }}
        title={
          hasWeb3Provider
            ? undefined
            : "You need a connected account to perform this action"
        }
      >
        <div className="d-flex gap-2">
          <img
            className="no-fade"
            src="/images/vote.svg"
            alt="vote"
            height={18}
          />{" "}
          <small>Vote applicant</small>
        </div>
        <Countdown
          unixDeadline={
            member.poll !== undefined
              ? member.poll.commitEndDate * 1000
              : member.applicationExpiry
          }
          onEnd={() => {
            // Updates the member card
            update();
            dispatch(guildActions.willUpdateMember(member.data));
          }}
        />
      </div>
    );
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log("Submitting vote");
    console.log(side);
    dispatch(
      guildActions.voteApplicant({
        name: member.data,
        vote: side,
        amount: fromDecimals(amount, token.decimals),
      })
    );
    handleClose();
  };
  return (
    <div>
      <Btn />
      <Modal show={show} onHide={handleClose} className="p-3">
        {memberIsUser ? (
          <Container className="px-4 py-3 text-center">
            <span>You can't vote yourself!</span>
          </Container>
        ) : (
          <Container className="px-4 py-3">
            <h5 className="fw-bold my-2 text-center">
              Do you want to accept {member.data} within {details.name}?
            </h5>
            {!userAddress && (
              <div className="text-center">
                <small className="text-primary">
                  You need a connected account to perform this action
                </small>
              </div>
            )}
            <Form onSubmit={handleSubmit}>
              <div className="d-flex  justify-content-center">
                <MemberMiniCard member={member} memberInfo={memberInfo} />
              </div>

              {/* Placeholder text */}
              <section className="text-muted py-4">
                Vote by staking your desired amount, in the case that your side
                wins it will be returned and you'll get a reward proportionate
                to your stake, otherwise it will go to the other voting side.
              </section>

              <div className="d-flex mx-3 align-items-center justify-content-center mb-5">
                <Form.Control
                  style={{ width: 180 }}
                  className="border-0 border-bottom border-black rounded-0"
                  type="number"
                  min={decimalsDeposit}
                  max={userAllowance}
                  step={toDecimals(1, token.decimals)}
                  placeholder={`Min stake: ${decimalsDeposit} ${token.symbol}`}
                  onChange={(e: any) => {
                    setAmount(e.target.value);
                  }}
                />
              </div>
              {/* Confirmation buttons */}
              <div
                className={`d-flex ${
                  isMobile ? "flex-column-reverse gap-2" : "flex-row"
                } justify-content-around mb-2 `}
              >
                <Button
                  disabled={!hasWeb3Provider || userAllowance < decimalsDeposit}
                  size="sm"
                  style={{ height: 40, width: isMobile ? "100%" : 150 }}
                  variant="transparent"
                  onClick={() => setSide(false)}
                  type="submit"
                >
                  <small>NO !</small>
                </Button>
                <div
                  title={
                    hasWeb3Provider
                      ? undefined
                      : "You need a connected account to perform this action"
                  }
                >
                  <Button
                    disabled={
                      !hasWeb3Provider || userAllowance < decimalsDeposit
                    }
                    size="sm"
                    style={{ height: 40, width: isMobile ? "100%" : 150 }}
                    onClick={() => setSide(true)}
                    type="submit"
                  >
                    <small>YES !</small>
                  </Button>
                </div>
              </div>
            </Form>
          </Container>
        )}
      </Modal>
    </div>
  );
};
export default Vote;
