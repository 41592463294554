export const NotFound = () => {
  return (
    <main>
      <b className="fs-1">404</b>
      <br />
      <strong className="text-muted">
        The resource you are looking for does not exist{" "}
      </strong>
    </main>
  );
};
export default NotFound;
