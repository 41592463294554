import { Outlet } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap";
import GuildsBreadcrumb from "../components/GuildsBreadcrumb";
import Header from "./Header";
import { selectors as userSelectors } from "../store/slices/user";
import FatalError from "../components/FatalError";
import { useSelector } from "react-redux";
export const Body = () => {
  const initialized = useSelector(userSelectors.initialized);
  const fatalError: false | Error = useSelector(userSelectors.fatalError);
  const isMobile = useSelector(userSelectors.isMobile);
  return (
    <div
      style={{ overflowY: "auto", overflowX: "hidden" }}
      className={`w-100 ${isMobile ? "" : "ms-5"} animated-mount`}
    >
      <Header />
      <Container
        className={`${isMobile ? "p-4" : "m-1"} justify-content-center`}
      >
        {initialized ? (
          <div className="animated-mount">
            {!isMobile && !fatalError && <GuildsBreadcrumb />}

            {fatalError ? <FatalError error={fatalError} /> : <Outlet />}
          </div>
        ) : (
          <div className="animated-mount w-100 d-flex justify-content-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Body;
