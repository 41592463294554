import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { actions as guildActions } from "../store/slices/guild";
import { Signer } from "ethers";
// Uses signer as param so that whenever the authority changes it will rerefresh
export const GuildProvider = ({signer}:{signer:Signer|undefined}) => {
  const dispatch = useDispatch();
  const { guildAddress } = useParams();
  useEffect(() => {
    dispatch(guildActions.setAddress(guildAddress))
    dispatch(guildActions.willConnectContracts(guildAddress));
  }, [dispatch, guildAddress]);
  return <Outlet />;
};
export default GuildProvider
