import { Card, Button, ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectors as userSelectors } from "../../store/slices/user";
import { selectors as guildSelectors } from "../../store/slices/guild";
import moment from "moment";
import { MemberDetails } from "../../api/types";
export type RewardProps = {
  votesFor: number;
  votesAgainst: number;
  side: boolean;
  date?: number;
  challAddress: string;
  claimAction: React.MouseEventHandler<HTMLButtonElement>;
};
export const Reward = ({
  votesFor,
  votesAgainst,
  side,
  date,
  challAddress,
  claimAction,
}: RewardProps) => {
  const isWinningSide =
    (side && votesFor >= votesAgainst) || (!side && votesFor < votesAgainst);

  const isMobile = useSelector(userSelectors.isMobile);
  const challenger: MemberDetails | undefined = useSelector(
    guildSelectors.members
  ).find((m) => {
    return m.owner === challAddress;
  });
  return (
    <>
      {date && (
        <i className="text-darkgray">
          {moment.unix(date).format("DD/MM/yyyy HH:mm:ss")}
        </i>
      )}
      <Card
        className={`d-flex flex-column shadow-sm border-0 m-2 p-3 pb-1 ${
          isMobile ? "" : "me-5"
        }`}
      >
        <div
          className={`d-flex ${
            isMobile ? "flex-column" : "flex-row"
          } border-0 m-2 `}
        >
          <div
            style={{ opacity: isWinningSide ? 1 : 0.7 }}
            className="d-flex flex-column flex-grow-1 me-3"
          >
            <h5 className="text-lightblack">
              You <b>{isWinningSide ? "WON" : "LOST"}</b> a challenge poll by
              voting{" "}
              {side ? (
                <b className="text-primary">FOR</b>
              ) : (
                <b className="text-purple">AGAINST</b>
              )}
            </h5>
            <ProgressBar
              max={votesFor + votesAgainst}
              style={{ width: "100%" }}
            >
              <ProgressBar
                animated={side && isWinningSide}
                variant="primary"
                now={votesFor}
                key={1}
                label={`${votesFor}`}
              />
              <ProgressBar
                animated={!side && isWinningSide}
                variant="purple"
                now={votesAgainst}
                key={2}
                label={`${votesAgainst}`}
              />
            </ProgressBar>
            <div className="d-flex justify-content-between">
              <small className="fw-bold text-primary">FOR</small>
              <small className="fw-bold text-purple">AGAINST</small>
            </div>
          </div>
          {isWinningSide && (
            <Button onClick={claimAction}>
              <span className="text-nowrap">Claim Reward <i className="bi bi-trophy" /></span>
            </Button>
          )}
        </div>
        <div className="text-end">
          Challenger: <i className="fw-bold">{challenger && challenger.data}</i>
        </div>
      </Card>
    </>
  );
};

export default Reward;
