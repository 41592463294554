import { signer } from "./provider";
import { getAwsEndpoints } from "./environment";
/**  Uploads a pdf, an image and a JSON to an S3 bucket */

export const upload = async ({
  pdf,
  image,
  json,
}: {
  pdf?: File;
  image?: File;
  json?: object;
}) => {
  if (!signer) {
    // Signer (logged account) is required for the upload
    return;
  }
  const { json_url, pdf_url, image_url } = await getUrls();
  // Uploads each file to the corresponding URL
  if (pdf) {
    await fetch(pdf_url, {
      headers: {
        "Content-Type": "application/pdf",
      },
      method: "PUT",
      body: pdf,
    });
  }

  if (image) {
    await fetch(image_url, {
      headers: {
        "Content-Type": "image/png",
      },
      method: "PUT",
      body: image,
    });
  }

  if (json) {
    await fetch(json_url, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: new File([JSON.stringify(json)], "metadata.json", {
        type: "application/json",
      }),
    });
  }
};

/**
 *
 * - Asks S3 for an authoken
 * - Asks the user to sign the token (using the provider API)
 * - Sends back the token, the signed token, and the user address
 * - Receives the links for the upload
 *
 * @returns An object containing 3 urls, { json_url, pdf_url, image_url }
 */
const getUrls = async () => {
  if (!signer) {
    // Signer (logged account) is required for the upload
    return;
  }

  // Requesting authtoken...
  const { authtoken } = await fetch(getAwsEndpoints().get).then((resp) => {
    return resp.json();
  });

  const userAddress: string = await signer.getAddress();
  const signedToken: string = await signer.signMessage(authtoken);
  const body = {
    address: userAddress,
    unsigned_token: authtoken,
    signed_token: signedToken,
  };

  // Fetches the URLs
  const res = await fetch(getAwsEndpoints().post, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(body),
  }).then((resp) => resp.json());

  return res.urls;
};
