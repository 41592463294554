import { useState } from "react";
import { Button, Modal, Container, Form } from "react-bootstrap";
import MemberMiniCard from "../cards/MemberMini";
import { useSelector, useDispatch } from "react-redux";
import { selectors as userSelectors } from "../../store/slices/user";
import {
  selectors as guildSelectors,
  actions as guildActions,
} from "../../store/slices/guild";
import { fromDecimals, toDecimals } from "../../utils";
import Countdown from "../Countdown";

import { MemberInfo } from "../cards/Member";
import { MemberDetails } from "../../api/types";
// import styles from "./modals.module.scss";
export const Challenge = ({
  member,
  memberInfo,
  update,
}: {
  member: MemberDetails;
  memberInfo: MemberInfo;
  update: Function;
}) => {
  const dispatch = useDispatch();
  const token = useSelector(guildSelectors.token);
  const details = useSelector(guildSelectors.details);
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const hasWeb3Provider = useSelector(userSelectors.web3Provider);
  const isMobile = useSelector(userSelectors.isMobile);
  const userAddress = useSelector(userSelectors.address);
  const memberIsUser = userAddress === member.owner;
  const decimalsDeposit = toDecimals(member.deposit, token.decimals);
  const allowance = useSelector(guildSelectors.userAllowance);

  const Btn = () => {
    return (
      <div
        className={`w-100 d-flex justify-content-between align-items-center px-4 pb-3 pt-4 fw-bold text-white ${userAddress?"hover-darken":""}  rounded-3`}
        role="button"
        onClick={handleShow}
        style={{ height: 60 }}
        title={
          hasWeb3Provider
            ? undefined
            : "You need a connected account to perform this action"
        }
      >
        <div className="d-flex gap-2">
          <img className="no-fade" src="/images/challenge.svg" alt="vote" height={20} />{" "}
          <small>Open challenge </small>
        </div>
        <Countdown
          unixDeadline={
            member.poll !== undefined
              ? member.poll.commitEndDate * 1000
              : member.applicationExpiry
          }
          onEnd={() => {
            // Updates the member card
            update();
            dispatch(guildActions.willUpdateMember(member.data));
          }}
        />
      </div>
    );
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      guildActions.challengeApplicant({
        name: member.data,
        amount: fromDecimals(amount, token.decimals),
      })
    );
    handleClose();
  };
  return (
    <div>
      <Btn />
      <Modal show={show} onHide={handleClose} className="p-3">
        {memberIsUser ? (
          <Container className="px-4 py-3 text-center">
            <span>You can't challenge yourself!</span>
          </Container>
        ) : (
          <Container className="px-4 py-3">
            <h5 className="fw-bold my-2 text-center">
              Challenge {member.data} from applying to {details.name}?
            </h5>
            {!userAddress && (
              <div className="text-center">
                <small className="text-primary">
                  You need a connected account to perform this action
                </small>
              </div>
            )}
            <div className="d-flex  justify-content-between">
              <MemberMiniCard member={member} memberInfo={memberInfo} />
              <div className="d-flex flex-column justify-content-center"></div>
            </div>

            {/* Placeholder text */}
            <section className="text-muted py-4">
              <small>
                By challenging an applicant you'll start a poll, which will
                determine whether the applicant will be accepted or not. At the
                end of the poll, the stake pool will be shared to each voter of
                the winning side, proportionally to their staked amount.
              </small>
            </section>
            <Form onSubmit={handleSubmit}>
              <div className="d-flex mx-3 align-items-center justify-content-between mb-5">
                <span className="text-muted">{token.symbol}</span>
                <Form.Control
                  style={{ width: 160 }}
                  className="border-0 border-bottom border-black rounded-0"
                  type="number"
                  min={decimalsDeposit}
                  max={allowance}
                  step={toDecimals(1, token.decimals)}
                  placeholder={`Min stake: ${decimalsDeposit}`}
                  onChange={(e: any) => {
                    setAmount(e.target.value);
                  }}
                />
              </div>
              {/* Confirmation buttons */}
              <div
                className={`d-flex ${
                  isMobile ? "flex-column gap-2" : "flex-row"
                } justify-content-around mb-2 `}
              >
                <Button
                  size="sm"
                  style={{ height: 40, width: isMobile ? "100%" : 150 }}
                  variant="transparent"
                  onClick={handleClose}
                >
                  <small>CANCEL</small>
                </Button>
                <div
                  title={
                    hasWeb3Provider
                      ? undefined
                      : "You need a connected account to perform this action"
                  }
                >
                  <Button
                    disabled={
                      amount < toDecimals(member.deposit, token.decimals) ||
                      amount > allowance
                    }
                    size="sm"
                    type="submit"
                    style={{ height: 40, width: isMobile ? "100%" : 150 }}
                  >
                    <small>CHALLENGE</small>
                  </Button>
                </div>
              </div>
            </Form>
          </Container>
        )}
      </Modal>
    </div>
  );
};
export default Challenge;
