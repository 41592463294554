import { useState } from "react";
import { Button, Modal, Container } from "react-bootstrap";
import { selectors as guildSelectors } from "../../store/slices/guild";
import { useSelector } from "react-redux";
import CopyButton from "../CopyButton";
import { shorten } from "../../utils";

export const Tutorial = ({className="", buttonText=""}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const faucetAmount = 100;
  const { minDeposit } = useSelector(guildSelectors.details);
  const token = useSelector(guildSelectors.token);
  const faucetAddress = "0xe4fd8fd64876641904c2718870554a64e99d0867"
  return (
    <>
      <Button className={className} onClick={handleShow}>
        <span>{buttonText?buttonText:"Tutorial"}</span> <i className="ms-1 bi bi-info-circle" />
      </Button>
      <Modal show={show} onHide={handleClose} className="p-3 px-1 mx-1">
        <Modal.Header className="bg-primary text-center text-white fw-bold">
          Welcome to the EthMusicians Guild!
        </Modal.Header>
        <Container className="px-3 py-4">
          <div>
            <h5>Basics:</h5>
            <div>
              The <b className="text-primary">EthMusicians</b> Guild is a Token
              Curated List, where the items listed are the same member curating
              the list, via the <i className="text-primary"> {token.symbol}</i>{" "}
              ({token.name}). <br /> <br />
              To begin with, make sure:
              <ul>
                <li className="text-primary">
                  If you are on mobile, you preferably use <b>Metamask</b> or{" "}
                  <b>Coinbase Wallet</b>
                </li>
                <li>
                  You are on <b>Goerli Testnet</b>
                </li>
                <li>
                  You have <b>GoerliETH</b> in your wallet (Faucets:{" "}
                  <i>
                    <a
                      href="https://goerlifaucet.com/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Alchemy
                    </a>
                    ,{" "}
                    <a
                      href="https://faucet.paradigm.xyz/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Paradigm
                    </a>
                  </i>
                  )
                </li>
              </ul>
            </div>
            <h5>For Musicians applying to play:</h5>

            <div>
              <ul>
                <li>
                  {" "}
                  In order to join the list you need EMG tokens =&gt; send a 0
                  ETH transaction to this faucet address <CopyButton longString={faucetAddress}>{shorten(faucetAddress)}</CopyButton> in order to
                  claim your first batch of {faucetAmount} EMG
                </li>
                <li>
                  Increase the token allowance to a minimum of {minDeposit}{" "}
                  {token.symbol}{" "}
                </li>
                <li>Create your profile and submit your application</li>
                <li>
                  If no one challenges your application during the probation
                  time, you will get listed and ready to rock the stage.
                </li>
              </ul>
            </div>
            <h5>For Music Lovers, fans willing to interact with the Guild:</h5>
            <div>
              <ul>
                <li>Simply connect your wallet to the Dapp </li>
                <li>Enjoy the show :)</li>
                <li>
                  Click <b>Award</b> button in the musician's card to cheerish
                  your favorite musicians
                </li>
              </ul>
            </div>
            <small className="text-primary mb-2">
              If you experience troubles when using Brave Browser, try disabling
              Brave Shields for this page (lion icon near the searchbar) and
              refresh.
            </small>
            <Button className="w-100" onClick={handleClose}>
              Ok!
            </Button>
          </div>
        </Container>
      </Modal>
    </>
  );
};
export default Tutorial;
