import { useState } from "react";
import { Button, Modal, Container, Form } from "react-bootstrap";
import MemberMiniCard from "../cards/MemberMini";
import { useSelector, useDispatch } from "react-redux";
import { selectors as userSelectors } from "../../store/slices/user";
import { actions as guildActions } from "../../store/slices/guild";
import { MemberInfo } from "../cards/Member";
import { MemberDetails } from "../../api/types";
export const Update = ({
  member,
  memberInfo,
}: {
  member: MemberDetails;
  memberInfo: MemberInfo;
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const hasWeb3Provider = useSelector(userSelectors.web3Provider);
  const userAddress = useSelector(userSelectors.address);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(guildActions.updateStatus(member.data));
    handleClose();
  };
  const isMobile = useSelector(userSelectors.isMobile);
  return (
    <div>
      <div
        title={
          hasWeb3Provider
            ? undefined
            : "You need a connected account to perform this action"
        }
      >
        <div
          className={`w-100 d-flex justify-content-between align-items-center px-4 pb-3 pt-4 fw-bold text-white ${
            userAddress ? "hover-darken" : ""
          }  rounded-3`}
          role="button"
          onClick={handleShow}
          style={{ height: 60 }}
          title={
            hasWeb3Provider
              ? undefined
              : "You need a connected account to perform this action"
          }
        >
          Update status
          <i className="bi bi-person-check" />
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="p-3">
        <Container className="px-4 py-3">
          <h5 className="fw-bold my-2 text-center">
            Update {member.data} application status?
          </h5>
          {!userAddress && (
            <div className="text-center">
              <small className="text-primary">
                You need a connected account to perform this action
              </small>
            </div>
          )}
          <div className="d-flex  justify-content-between mb-3">
            <MemberMiniCard member={member} memberInfo={memberInfo} />
            <div className="d-flex flex-column justify-content-center"></div>
          </div>
          <Form onSubmit={handleSubmit}>
            {/* Confirmation buttons */}

            <div
              className={`d-flex ${
                isMobile ? "flex-column gap-2" : "flex-row"
              } justify-content-around mb-2 `}
            >
              <Button
                size="sm"
                style={{ width: isMobile ? "100%" : 150, height: 40 }}
                variant="transparent"
                onClick={handleClose}
              >
                <small>CANCEL</small>
              </Button>
              <Button
                disabled={!hasWeb3Provider}
                size="sm"
                type="submit"
                style={{ width: isMobile ? "100%" : 150, height: 40 }}
              >
                <small>UPDATE STATUS</small>
              </Button>
            </div>
          </Form>
        </Container>
      </Modal>
    </div>
  );
};
export default Update;
