export const shorten = (address: string) => {
  if (address.length < 20) return address;
  return (
    address.slice(0, 5) +
    "..." +
    address.slice(address.length - 4, address.length)
  );
};

/** Converts an amount to its respective token decimal counterpart */
export const toDecimals = (amount: number, decimals: number) => {
  return amount / Math.pow(10, decimals);
};

/** Converts an amount to its non-decimal counterpart */
export const fromDecimals = (amount: number, decimals: number) => {
  return amount * Math.pow(10, decimals);
};
