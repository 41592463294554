import { Button, Spinner } from "react-bootstrap";
// import { OnboardingButton } from "../components/OnboardingButton";
import { selectors as guildSelectors } from "../store/slices/guild";
import { selectors as userSelectors } from "../store/slices/user";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { SetAllowance as SetAllowanceModal } from "../components/modals/SetAllowance";
import Web3Button from "../components/Web3Button";

export const Header = () => {
  const { guildAddress } = useParams();
  const initialized = useSelector(userSelectors.initialized);
  const userAddress = useSelector(userSelectors.address);
  const balance = useSelector(guildSelectors.userBalance);
  const allowance = useSelector(guildSelectors.userAllowance);
  const token = useSelector(guildSelectors.token);
  const isMobile = useSelector(userSelectors.isMobile);
  if (isMobile) return <></>;
  return (
    <div
      style={{ zIndex: 500 }}
      className={"d-flex justify-content-between align-items-center"}
    >
      {guildAddress && userAddress && initialized ? (
        <div className="d-flex align-items-center">
          <SetAllowanceModal
            button={
              <Button
                variant="transparent"
                className="border-2 d-flex flex-row align-items-center me-3 h-100"
              >
                <i className="bi bi-cash-stack" />{" "}
                <small className="ps-2">Set allowance</small>
              </Button>
            }
          />
          <div>
            <div>
              <span className="text-muted">Your balance:</span>{" "}
              <strong>
                {balance === -1 ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  balance
                )}
              </strong>
              <small title={token.name} className="ms-1 fw-light text-primary">
                {token.symbol}
              </small>
            </div>
            <div>
              <span className="text-muted">Allowance:</span>{" "}
              <strong>
                {allowance === -1 ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  allowance
                )}
              </strong>
              <small title={token.name} className="ms-1 fw-light text-primary">
                {token.symbol}
              </small>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
      {/* <OnboardingButton /> */}
      <div className="web3-button p-2">
        <Web3Button />
      </div>
    </div>
  );
};

export default Header;
