import { createSlice } from "@reduxjs/toolkit";
import { GuildDetails, ListingDetails } from "../../api/types";
import { GUILDS_ADDRESSES } from "../../api/environment";
export type Notification = {
  type: string;
  guildAddress: string;
  member?: ListingDetails;
  isNew: boolean;
  data: any;
};
export type Toast = {
  title: string;
  body: string;
  show: boolean;
  variant: string;
  delay: number;
};
const initialState = {
  addresses: [...GUILDS_ADDRESSES],
  guilds: [] as GuildDetails[],
  notifications: [] as Notification[],
  toasts: [] as Toast[],
  initialized: false,
};
type State = typeof initialState;

const transactionHashes: string[] = [];
export const guildsSlice = createSlice({
  name: "explorer",
  initialState,
  reducers: {
    willGetGuilds: (state: State) => state,
    didGetGuilds: (state: State, action) => {
      state.guilds = action.payload;
      state.initialized = true;
      return state;
    },
    willPushNotification: (state: State, action) => state,
    didPushNotification: (state: State, action) => {
      if (!transactionHashes.includes(action.payload.transactionHash)) {
        transactionHashes.push(action.payload.transactionHash);
        state.notifications.unshift(action.payload.notification);
        console.log("Did push the notification")
      } else {
        console.log("Didnt push notification because txHash is already in the hashPool.", {
          txHash:action.payload.transaction,
          pool:transactionHashes
        });
      }
      return state;
    },
    pushToast: (state: State, action) => {
      const { title, body, variant, delay } = action.payload.toast;

      if (!transactionHashes.includes(action.payload.transaction)) {
        transactionHashes.push(action.payload.transaction);
        state.toasts.unshift({
          title,
          body,
          show: true,
          variant: variant || "primary",
          delay: delay || 15000,
        });
      }
      return state;

    },
    closeToast: (state: State, action) => {
      const index = action.payload;
      state.toasts[index].show = false;
      return state;
    },
    viewNotification: (state: State, action) => {
      const index = action.payload;
      state.notifications[index].isNew = false;
      return state;
    },
    viewAllNotifications: (state: State) => {
      for (let notification of state.notifications) {
        notification.isNew = false;
      }
      return state;
    },
  },
});

export const selectors = {
  addresses: (state: { explorer: State }) => state.explorer.addresses,
  guilds: (state: { explorer: State }) => state.explorer.guilds,
  getGuildByHash: (state: { explorer: State }, action: any) => {
    const hash = action.payload;
    return state.explorer.guilds[state.explorer.addresses.indexOf(hash)];
  },
  notifications: (state: { explorer: State }) => state.explorer.notifications,
  toasts: (state: { explorer: State }) => state.explorer.toasts,
  notificationsAmount: (state: { explorer: State }) => {
    const nots: Notification[] = state.explorer.notifications;
    return nots.reduce((acc, not) => {
      return not.isNew ? acc + 1 : acc;
    }, 0);
  },
  notificationsAmountForGuild: (state: { explorer: State }, guild: string) => {
    const nots: Notification[] = state.explorer.notifications;
    return nots.reduce((acc, not) => {
      return not.isNew && not.guildAddress === guild ? acc + 1 : acc;
    }, 0);
  },
};

export const { actions, reducer } = guildsSlice;
