import { Form } from "react-bootstrap";
import { useState } from "react";
type Props = {
  title?: string;
  onChange?: Function;
  nativeProps?: any;
};
export const FormInput = ({
  title = "",
  nativeProps = { type: "text", required: true },
  onChange = () => {},
}: Props) => {
  const [valid, setValid] = useState(false);
  return (
    <div className="d-flex border-bottom border-black justify-content-between">
      <div className="d-flex flex-column mx-1 flex-fill">
        <small style={{ marginBottom: -5 }} className="text-darkgray">
          {title}
        </small>
        <Form.Control
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { validity, value } = e.target;
            validity.valid && value ? setValid(true) : setValid(false);
            onChange(e);
          }}
          required
          className="guilds-input w-100"
          {...nativeProps}
        />
      </div>
      {valid && (
        <div className="bg-primary rounded-3 d-flex justify-content-center align-items-center m-2">
          <i className="px-4 py-2 bi bi-check2 text-white" />
        </div>
      )}
    </div>
  );
};

export default FormInput;
