import { Button } from "react-bootstrap";

export const GuildsMetamask = () => {
  return (
    <main>
      <h1>What is metamask?</h1>
      <section className="col-8 text-muted mb-5">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas
        lectus tortor, vel lacinia odio mollis sed. Donec quis pharetra
        tortor.https://metamask.io/download/
      </section>
      <Button href="https://metamask.io/download/" target="_blank">
        Download Metamask
      </Button>
    </main>
  );
};
export default GuildsMetamask;
