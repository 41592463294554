import { Button, Modal, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectors as userSelectors } from "../../store/slices/user";
import { useNetwork, ChainId, useDisconnect } from "@thirdweb-dev/react";
import {  expectedChainId } from "../../api/provider";
import { ENVIRONMENT } from "../../api/environment";
export const ChangeNetwork = () => {
  const mismatch = useSelector(userSelectors.networkMismatch);
  const [, switchNetwork] = useNetwork();
  const disconnect = useDisconnect();
  const onChangeNetwork = (e: any) => {
    if (switchNetwork) {
      switchNetwork(expectedChainId(ENVIRONMENT)).then((a) =>
        window.location.reload()
      );
    }
  };
  const onDisconnect = (e: any) => {
    disconnect().then((a) => window.location.reload());
  };
  const isMobile = useSelector(userSelectors.isMobile);
  return (
    <div>
      <Modal show={!!mismatch} className="p-2">
        <Container className="px-4 py-3">
          <h5 className="fw-bold my-3">Switch web3 Network</h5>

          {mismatch && (
            <p>
              At the moment, <b className="text-primary">Guilds</b>{" "}
              doesn't support the{" "}
              <b>
                {ChainId[mismatch.currentChain]
                  ? ChainId[mismatch.currentChain]
                  : `"${mismatch.currentChain}"`}
              </b>{" "}
              network, you will have to switch to{" "}
              <b>
                {ChainId[mismatch.desiredChain]
                  ? ChainId[mismatch.desiredChain]
                  : `"${mismatch.desiredChain}"`}
              </b>{" "}
              in order for the application to run properly.
              {!switchNetwork && (
                <p className="text-primary">
                  Unfortunately, your current wallet provider does not support programmatic network switching.
                </p>
              )}
            </p>
          )}
          {/* Confirmation buttons */}
          <div
            className={`d-flex ${
              isMobile ? "flex-column gap-2" : "flex-row"
            } justify-content-around mb-2`}
          >
            <small>
              <Button
                size="sm"
                type="submit"
                style={{ width: isMobile ? "100%" : 150, height: 40 }}
                onClick={onChangeNetwork}
                disabled={!switchNetwork}
              >
                CHANGE NETWORK
              </Button>
            </small>
          <Button
            size="sm"
            type="submit"
            style={{ width: isMobile ? "100%" : 150, height: 40 }}
            onClick={onDisconnect}
            variant="transparent"
            className="mt-1"
          >
            DISCONNECT
          </Button>
          </div>
        </Container>
      </Modal>
    </div>
  );
};
export default ChangeNetwork;
