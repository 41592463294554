import { useState } from "react";
import { Button, Modal, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fromDecimals } from "../../utils";
import { selectors as userSelectors } from "../../store/slices/user";
import TutorialModal from "./Tutorial";
import {
  actions as guildActions,
  selectors as guildSelectors,
} from "../../store/slices/guild";

type Props = {
  button: React.ReactNode | React.ReactNode[];
  amount: number;
};
export const HintAllowance = ({ button, amount }: Props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const isMobile = useSelector(userSelectors.isMobile);
  const allowance = useSelector(guildSelectors.userAllowance);
  const balance = useSelector(guildSelectors.userBalance);
  const token = useSelector(guildSelectors.token);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      guildActions.willSetUserAllowance({
        amount: Math.floor(fromDecimals(allowance + amount, token.decimals)),
      })
    );
    handleClose();
  };

  return (
    <>
      <div className="d-flex justify-content-center" onClick={handleShow}>
        {button}
      </div>
      <Modal show={show} onHide={handleClose} className="p-3">
        <Form onSubmit={handleSubmit}>
          <Container className="px-4 py-3">
            <h5 className="fw-bold mt-3">Top up allowance</h5>

            <section className="text-muted pb-4">
              Top-up your allowance by <b>{amount}</b>
              <small>{token.symbol}</small>?
            </section>

            <div className="d-flex justify-content-between">
              <div>
                <span className="text-muted">balance:</span>
                <br /> {balance}
                <small className="ms-1 fw-light ">{token.symbol}</small>
              </div>

              <div>
                <span className="text-muted">current allowance:</span>
                <br /> {allowance}
                <small className="ms-1 fw-light ">{token.symbol}</small>
              </div>
            </div>

            {/* Confirmation buttons */}
            <div
              className={`d-flex mt-3 ${
                isMobile ? "flex-column gap-2" : "flex-row"
              } justify-content-around mb-2 `}
            >
              <Button
                size="sm"
                style={{ height: 40, width: isMobile ? "100%" : 150 }}
                variant="transparent"
                onClick={handleClose}
              >
                <small>CANCEL</small>
              </Button>
              <Button
                size="sm"
                style={{ height: 40, width: isMobile ? "100%" : 150 }}
                type="submit"
                disabled={balance < allowance + amount}
              >
                <small>TOP-UP</small>
              </Button>
            </div>
            {balance === 0 && (
              <div className="text-center">
                <small className="text-primary">
                  You don't have {token.symbol} tokens in your wallet
                </small>
                <br />
                <b role="button" className=" a-like">
                  Follow the tutorial to get {token.symbol} tokens
                </b>
              </div>
            )}
          </Container>
        </Form>
      </Modal>
    </>
  );
};
export default HintAllowance;
