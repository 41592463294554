import { ethers } from "ethers";
import {
  ListingDetails,
  TokenDetails,
  Poll,
  Challenge,
  GuildDetails,
  Reaction,
} from "./types";

/** String -> UTF -> HEX -> pads it to 32 */
export const hexify = (string: string) => {
  return ethers.utils.hexZeroPad(
    ethers.utils.hexlify(ethers.utils.toUtf8Bytes(string)),
    32
  );
};

export const hexToString = (string: string) => {
  var hex = new TextDecoder().decode(ethers.utils.stripZeros(string));
  return hex;
};

const isTooOld = async (evCallback: Function, ...args: any) => {
  const max_age = 60;
  const eventMetadata = args[args.length - 1];
  const block = await eventMetadata.getBlock();
  if (Math.floor(Date.now() / 1000) < block.timestamp + max_age) {
    evCallback(...args);
  } else {
    console.log("Too old!");
  }
};
export const setEvent = async (
  connectedContract: ethers.Contract,
  evName: string,
  evCallback: ethers.providers.Listener
) => {
  return await errorWrapper(async () =>
    connectedContract.on(evName, (...args) => {
      console.log("Triggered " + evName);
      isTooOld(evCallback, ...args);
    })
  );
};
export const getPollNonce = async (
  connectedTcr: ethers.Contract
): Promise<Number | Error> => {
  return await errorWrapper(async () => Number(await connectedTcr.pollNonce()));
};
export const getGuildDetails = async (
  connectedTcr: ethers.Contract
): Promise<GuildDetails | Error> => {
  return await errorWrapper(async () => {
    const [
      name,
      tokenAddress,
      minDeposit,
      applyStageLen,
      commitStageLen,
      description,
    ] = await connectedTcr.getDetails();
    return {
      name,
      tokenAddress,
      minDeposit: Number(minDeposit),
      applyStageLen: Number(applyStageLen),
      commitStageLen: Number(commitStageLen),
      description,
    } as GuildDetails;
  });
};

export const canClaim = async (
  connectedTcr: ethers.Contract,
  challengeId: number
): Promise<boolean | Error> => {
  return await errorWrapper(
    async () => await connectedTcr.canClaim(challengeId)
  );
};
export const getPoll = async (
  connectedTcr: ethers.Contract,
  challengeId: number
): Promise<Poll | Error> => {
  return await errorWrapper(async () => {
    const [votesFor, votesAgainst, commitEndDate, passed] =
      await connectedTcr.getPoll(challengeId);
    return {
      votesFor: Number(votesFor),
      votesAgainst: Number(votesAgainst),
      commitEndDate: Number(commitEndDate),
      passed,
    };
  });
};
export const getChallenge = async (
  connectedTcr: ethers.Contract,
  challengeId: number
): Promise<Challenge | Error> => {
  return await errorWrapper(async () => {
    const [challenger, resolved, stake, rewardPool, totalTokens] =
      await connectedTcr.getChallenge(challengeId);
    return {
      challenger: String(challenger),
      resolved: Boolean(resolved),
      stake: Number(stake),
      rewardPool: Number(rewardPool),
      totalTokens: Number(totalTokens),
    };
  });
};

export const getTokenDetails = async (
  connectedToken: ethers.Contract
): Promise<TokenDetails | Error> => {
  return await errorWrapper(async () => {
    const name: string = await connectedToken.name();
    const totalSupply: ethers.BigNumber = await connectedToken.totalSupply();
    const decimals: number = await connectedToken.decimals();
    const symbol: string = await connectedToken.symbol();

    return {
      name,
      totalSupply: Number(totalSupply),
      decimals: decimals,
      symbol,
    } as TokenDetails;
  });
};
export const getBalanceOf = async (
  connectedToken: ethers.Contract,
  address: string
): Promise<Number | Error> => {
  return await errorWrapper(async () => {
    const balance = await connectedToken.balanceOf(address);
    return parseInt(balance.toString());
  });
};
export const getAllowanceOf = async (
  connectedToken: ethers.Contract,
  owner: string,
  spender: string
): Promise<Number | Error> => {
  return await errorWrapper(async () => {
    const allowance = await connectedToken.allowance(owner, spender);
    return parseInt(allowance.toString());
  });
};
export const addToStake = async (
  connectedToken: ethers.Contract,
  connectedTcr: ethers.Contract,
  amount: number
): Promise<any | Error> => {
  return await errorWrapper(
    async () => await connectedToken.approve(connectedTcr.address, amount)
  );
};

/** Returns a list of members */
export const getListings = async (
  connectedTcr: ethers.Contract
): Promise<string[] | Error> => {
  return await errorWrapper(async () => await connectedTcr.getAllListings());
};

/** Given a listing name returns its details */
export const getListingDetails = async (
  connectedTcr: ethers.Contract,
  name: string
): Promise<ListingDetails | Error> => {
  return await errorWrapper(async () => {
    let [whitelisted, owner, deposit, challengeId, applicationExpiry, data] =
      await connectedTcr.getListingDetails(hexify(name));
    return {
      whitelisted,
      owner,
      deposit: Number(deposit),
      challengeId: Number(challengeId),
      applicationExpiry: Number(applicationExpiry) * 1000,
      data,
    };
  });
};
export const stake = async (
  connectedTcr: ethers.Contract,
  connectedToken: ethers.Contract,
  amount: number
): Promise<any | Error> => {
  return await errorWrapper(async () =>
    connectedToken.approve(connectedTcr.address, amount)
  );
};

/** Proposes a new application */
export const propose = async (
  connectedTcr: ethers.Contract,
  newListingName: string,
  amount: number,
  signerAddress: string
): Promise<any | Error> => {
  return await errorWrapper(async () =>
    connectedTcr.propose(hexify(newListingName), amount, newListingName, {
      from: signerAddress,
    })
  );
};
export const challenge = async (
  connectedTcr: ethers.Contract,
  listingName: string,
  amount: number
): Promise<any | Error> => {
  return await errorWrapper(async () =>
    connectedTcr.challenge(hexify(listingName), amount)
  );
};
export const vote = async (
  connectedTcr: ethers.Contract,
  listingName: string,
  amount: number,
  choice: boolean
): Promise<any | Error> => {
  return await errorWrapper(async () =>
    connectedTcr.vote(hexify(listingName), amount, choice)
  );
};
export const claimRewards = async (
  connectedTcr: ethers.Contract,
  challengeID: number
): Promise<any | Error> => {
  return await errorWrapper(async () => connectedTcr.claimRewards(challengeID));
};

/** Updates the status of a listing */
export const updateStatus = async (
  connectedTcr: ethers.Contract,
  name: string
): Promise<any | Error> => {
  return await errorWrapper(async () =>
    connectedTcr.updateStatus(hexify(name))
  );
};
export const getAllowance = async (
  connectedTcr: ethers.Contract,
  connectedToken: ethers.Contract,
  address: string
): Promise<Number | Error> => {
  return await errorWrapper(async () => {
    return Number(
      await connectedToken.allowance(address, connectedTcr.address)
    );
  });
};

/* Reaction contract */

export const addReaction = async (
  connectedReacts: ethers.Contract,
  address: string,
  reaction: Reaction
): Promise<any | Error> => {
  return await errorWrapper(async () => {
    console.log("Adding reaction:", address, reaction);
    const res = await connectedReacts.addReaction(address, reaction);
    console.log("`addReaction` result: ", res);
    return res;
  });
};
export const getFan = async (
  connectedReacts: ethers.Contract,
  address: string,
  index: number
): Promise<any | Error> => {
  return await errorWrapper(async () => {
    const res = await connectedReacts.fans(address, index);
    return res;
  });
};
export const getReaction = async (
  connectedReacts: ethers.Contract,
  address: string,
  index: number
): Promise<number | Error> => {
  return await errorWrapper(async () => {
    const res = await connectedReacts.reactions(address, index);
    return res;
  });
};
export const noOfReactions = async (
  connectedReacts: ethers.Contract,
  address: string
): Promise<Reaction | Error> => {
  return await errorWrapper(async () => {
    const res = Number(await connectedReacts.noOfReactions(address));
    return res;
  });
};
export const isFanOf = async (
  connectedReacts: ethers.Contract,
  fanAddress: string,
  artistAddress: string
): Promise<any | Error> => {
  return await errorWrapper(async () => {
    const res = await connectedReacts.isFanof(fanAddress, artistAddress);
    console.log("`isFanOf` result: ", res);
    return res;
  });
};

const errorWrapper = async (callback: Function, ...callbackArgs: any[]) => {
  try {
    return await callback(...callbackArgs);
  } catch (exception: unknown) {
    console.warn(exception);
    if (exception instanceof Error) {
      return exception;
    }
    return new Error("Generic error");
  }
};
