import { useState } from "react";
import { Button, Modal, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FormInput } from "../FormInput";
import { fromDecimals, toDecimals } from "../../utils";
import { selectors as userSelectors } from "../../store/slices/user";
import { actions as guildActions, selectors as guildSelectors } from "../../store/slices/guild";

type Props = {
  button: React.ReactNode | React.ReactNode[];
};
export const SetAllowance = ({
  button,
}: Props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [newAllowance, setNewAllowance] = useState(0);
  const isMobile = useSelector(userSelectors.isMobile);
  const token = useSelector(guildSelectors.token)
  const balance = useSelector(guildSelectors.userBalance)
  const allowance = useSelector(guildSelectors.userAllowance)
  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      guildActions.willSetUserAllowance({
        amount: Math.floor(fromDecimals(newAllowance, token.decimals)),
      })
    );
    handleClose();
  };

  return (
    <>
      <div className="d-flex justify-content-center" onClick={handleShow}>{button}</div>
      <Modal show={show} onHide={handleClose} className="p-3">
        <Form onSubmit={handleSubmit}>
          <Container className="px-4 py-3">
            <h5 className="fw-bold mt-3">Set account allowance</h5>

            <section className="text-muted pb-4">
              The allowance corresponds to the maximum amount of currency you'll
              permit to spend from your balance.
              <br />
              <br />
              <small>Note: This amount can be changed anytime</small>
            </section>

            <div className="d-flex justify-content-between">
              <div>
                <span className="text-muted">balance:</span>
                <br /> {balance}
                <small className="ms-1 fw-light ">{token.symbol}</small>
              </div>

              <div>
                <span className="text-muted">current allowance:</span>
                <br /> {allowance}
                <small className="ms-1 fw-light ">{token.symbol}</small>
              </div>
            </div>
            <div className="px-4 my-4">
              <FormInput
                nativeProps={{
                  type: "number",
                  min: toDecimals(1, token.decimals),
                  max: balance,
                  step: toDecimals(1, token.decimals),
                }}
                title={"Allowance:"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewAllowance(Number(e.target.value))
                }
              />
            </div>
            {/* Confirmation buttons */}
            <div
              className={`d-flex ${
                isMobile ? "flex-column gap-2" : "flex-row"
              } justify-content-around mb-2 `}
            >
              <Button
                size="sm"
                style={{ height: 40, width: isMobile ? "100%" : 150 }}
                variant="transparent"
                onClick={handleClose}
              >
                <small>CANCEL</small>
              </Button>
              <Button
                size="sm"
                style={{ height: 40, width: isMobile ? "100%" : 150 }}
                type="submit"
                disabled={newAllowance <= 0 || allowance < 0 || balance < newAllowance}
              >
                <small>SET ALLOWANCE</small>
              </Button>
            </div>
          </Container>
        </Form>
      </Modal>
      </>
  );
};
export default SetAllowance;
