import { getAwsEndpoints } from "../api/environment";

/**
 * Creates an uri param which changes depending on the current timestamp
 *
 * @param seconds Defines how much time to wait before changing the uri param (and the cache, by consequence)
 */
const cacheRefreshrate = (seconds: number) => {
  // 1660836884640
  const date = Date.now();
  return "?dummy=" + (date - (date % (seconds * 1000)));
};
export const ProfilePicture = ({
  size,
  address,
  className = "rounded-circle m-3",
  style
}: {
  size: number;
  address?: string;
  className?: string;
  style?:React.CSSProperties
}) => {
  const PLACEHOLDER_URL = "/images/profile_placeholder.jpg";
  return (
    <img

      src={
        address
          ? `${getAwsEndpoints().url}/resources/${address}/avatar.png`
          : PLACEHOLDER_URL
      }
      onError={(e) => {
        e.currentTarget.src = PLACEHOLDER_URL;
      }}
      className={className}
      style={{ ...style,width: size, height: size, objectFit: "cover" }}
      alt="profile"
    />
  );
};
export default ProfilePicture;
