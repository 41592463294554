import MemberCard from "../components/cards/Member";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { GuildDetails, MemberDetails } from "../api/types";
import { selectors as guildSelectors } from "../store/slices/guild";
import { selectors as userSelectors } from "../store/slices/user";
import TutorialModal from "../components/modals/Tutorial";
import { Spinner } from "react-bootstrap";
import CopyButton from "../components/CopyButton";
import { shorten } from "../utils";
import { ENVIRONMENT } from "../api/environment";

export const GuildMembers = () => {
  const navigate = useNavigate();
  const { name, description }: GuildDetails = useSelector(
    guildSelectors.details
  );
  const membersLoading = useSelector(guildSelectors.membersLoading);
  const membersLoaded = useSelector(guildSelectors.membersLoaded);
  const hasWeb3Provider = useSelector(userSelectors.web3Provider);
  const hasUser = useSelector(guildSelectors.hasUser);
  const userBalance = useSelector(guildSelectors.userBalance);
  const guildMembers: MemberDetails[] = useSelector(guildSelectors.members);
  const rewardsAmount = useSelector(guildSelectors.rewards).length;
  const isMobile = useSelector(userSelectors.isMobile);
  const { tokenAddress } = useSelector(guildSelectors.details);
  const guildAddress = useSelector(guildSelectors.address);

  const ActionButtons = () => {
    return (
      <div className={`${isMobile ? "row justify-content-around" : "d-flex "}`}>
        <div
          className={isMobile ? "col-6" : ""}
          title={
            hasWeb3Provider
              ? undefined
              : "You need a connected account to perform this action"
          }
        >
          <Button
            className={`py-2 me-3 text-nowrap  ${
              isMobile ? "px-1 w-100" : "px-3"
            }`}
            onClick={() => navigate("./rewards")}
            role="link"
            variant="transparent"
            disabled={!hasWeb3Provider}
            style={{ zIndex: 1000 }}
          >
            <small>Rewards</small>{" "}
            <span className="bi bi-trophy position-relative">
              {rewardsAmount > 0 && (
                <span
                  style={{ top: "-20px" }}
                  className="fs-6 position-absolute badge pill bg-primary"
                >
                  {rewardsAmount}
                </span>
              )}
            </span>
          </Button>
        </div>

        <div
          className={isMobile ? "col-6" : ""}
          title={
            !hasWeb3Provider
              ? "You need a connected account to perform this action"
              : hasUser
              ? "You are already a member of this guild"
              : undefined
          }
        >
          <Button
            className={`py-2 w-100 text-nowrap ${
              isMobile ? "px-1 w-100" : "px-3"
            }`}
            onClick={() => navigate("./apply")}
            disabled={!hasWeb3Provider || hasUser}
          >
            <small>Apply as member</small>{" "}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <main>
      <header className="justify-content-between d-flex flex-row mb-3 pb-3">
        {name ? (
          <div>
            <h1>{name}</h1>
            <div className="mb-3 fs-5">{description}</div>
            <div className="d-flex flex-wrap gap-2">
              {ENVIRONMENT === "goerli_testnet" && <TutorialModal className={userBalance?"":"pulse"}/>}
              <Button
                onClick={() => window.open("https://t.me/+UfpbJy3al6tmODJk")}
                variant="transparent"
              >
                Leave feedback <i className="bi bi-telegram" />
              </Button>
            </div>
            <br />
            <div className="d-flex gap-2 flex-wrap mt-3">
              <div className="text-muted">
                <small>Guild:</small>{" "}
                {isMobile ? (
                  <CopyButton
                    longString={guildAddress}
                    children={<span>{shorten(guildAddress)}</span>}
                  />
                ) : (
                  <small>{guildAddress}</small>
                )}
              </div>
              <div className="text-muted">
                <small>Token:</small>{" "}
                {isMobile ? (
                  <CopyButton
                    longString={tokenAddress}
                    children={<small>{shorten(tokenAddress)}</small>}
                  />
                ) : (
                  <small> {tokenAddress}</small>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{ height: 80 }}
            className="d-flex justify-content-center align-items-center w-100"
          >
            <Spinner animation="border" className="text-primary" />
          </div>
        )}
      </header>
      <div
        className={`d-flex ${
          isMobile ? "flex-column-reverse gap-3" : "justify-content-between"
        }`}
      >
        <h2 className="mb-5">Members</h2>
        <ActionButtons />
      </div>
      <div
        className={`d-flex ${
          isMobile ? "flex-column align-items-center" : "flex-row flex-wrap"
        } `}
      >
        {membersLoading && membersLoading !== membersLoaded ? (
          <Spinner animation="border" className="m-auto text-primary" />
        ) : guildMembers.length ? (
          guildMembers.map((member, i) => {
            return <MemberCard key={`${member.data}-${i}`} member={member} />;
          })
        ) : (
          <h2 className="fs-1 text-gray text-center my-5">No members yet</h2>
        )}
      </div>
    </main>
  );
};
export default GuildMembers;
