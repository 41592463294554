import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import MemberMiniCard from "../components/cards/MemberMini";
import {
  selectors as explorerSelectors,
  actions as explorerActions,
  Notification,
} from "../store/slices/explorer";
import { selectors as userSelectors } from "../store/slices/user";
import { tagList } from "../components/modals/Award";
import { shorten } from "../utils";

export const Notifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useSelector(userSelectors.isMobile);

  const notifications: Notification[] = useSelector(
    explorerSelectors.notifications
  );
  useEffect(() => {
    dispatch(explorerActions.viewAllNotifications());
  }, [dispatch]);
  return (
    <>
      {isMobile && (
        <div className="d-flex justify-content-end">
          <Button variant="light" onClick={() => navigate(-1)}>
            <i className="bi bi-x-circle fs-1" />
          </Button>
        </div>
      )}
      <div className="d-flex flex-column">
        <Container className="me-4">
          {notifications.length > 0 ? (
            notifications.map((notification, i) => {
              let notificationType: JSX.Element = <></>;
              switch (notification.type) {
                case "_Application":
                  notificationType = (
                    <h4 className="text-muted p-0 m-0">New Applicant!</h4>
                  );
                  break;
                case "_Challenge":
                  notificationType = (
                    <h4 className="text-muted p-0 m-0">
                      <i>{notification.member && notification.member.data}</i>{" "}
                      has been challenged!
                    </h4>
                  );
                  break;
                case "_ResolveChallenge":
                  notificationType = (
                    <h4 className="text-muted p-0 m-0">
                      <i>{notification.member && notification.member.data}</i>{" "}
                      challenge has been resolved!
                    </h4>
                  );
                  break;
                case "_UpdateStatus":
                  notificationType = (
                    <h4 className="text-muted p-0 m-0">
                      Updated status for{" "}
                      <i>{notification.member && notification.member.data}</i>
                    </h4>
                  );
                  break;
                case "member_removed":
                  notificationType = (
                    <h4 className="text-muted p-0 m-0">
                      <i>{notification.data.memberName}</i> has been expelled
                      from {notification.data.guild.name}!
                    </h4>
                  );
                  break;
                case "_RewardClaimed":
                  notificationType = (
                    <h4 className="text-muted p-0 m-0">
                      You earned <i>{notification.data.amount}</i> from a
                      challenge poll!
                    </h4>
                  );
                  break;
                case "ReactionAdded":
                  console.log(notification);
                  notificationType = (
                    <h4 className="text-muted p-0 m-0">
                      <i>{notification.member!.data}</i> got awarded for{" "}
                      <b>{tagList[notification.data.award][0]}</b> by{" "}
                      <i>{shorten(notification.data.awarder)}!</i>
                    </h4>
                  );
                  break;
              }
              return (
                <div
                  className="d-flex align-items-center shadow-sm px-3 my-3 rounded-3 flex-wrap"
                  key={`notification-${i}`}
                  role="button"
                  onClick={() => {
                    navigate(`/guilds/${notification.guildAddress}`);
                  }}
                >
                  {notificationType}
                  {notification.member && (
                    <MemberMiniCard member={notification.member} />
                  )}
                </div>
              );
            })
          ) : (
            <h2 className="fs-1 text-gray text-center my-5">
              No notifications
            </h2>
          )}
        </Container>
      </div>
    </>
  );
};
export default Notifications;
