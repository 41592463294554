import { Card, Button } from "react-bootstrap";
import ChallengeModal from "../modals/Challenge";
import UpdateModal from "../modals/Update";
import AwardModal, { Tag } from "../modals/Award";
import { getReactionsAddress } from "../../api/environment";
import VoteModal from "../modals/Vote";
import ProfilePicture from "../ProfilePicture";
import { MemberDetails, Reaction } from "../../api/types";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectors as userSelectors } from "../../store/slices/user";
import { tagList } from "../modals/Award";
import { useNavigate } from "react-router-dom";
import { getAwsEndpoints } from "../../api/environment";
export type MemberInfo = {
  title: string;
  link: string;
};

/** Takes a list of reactions and returns it back in a sorted/organized way
 * - Creates A list of tags
 */
export const processReactions = (
  reactionList: Reaction[] | undefined,
  fansList?: string[] | undefined
): { tag: Tag; count: number; fans: string[] }[] => {
  if (reactionList === undefined) {
    return [];
  }
  const newList = tagList.map((_, index) => {
    const reaction = {
      tag: tagList[index],
      count: 0,
      fans: [] as string[],
    };
    return reaction;
  });
  reactionList.forEach((reaction, i) => {
    if (newList[reaction]) {
      newList[reaction].count++;
      if (fansList) {
        newList[reaction].fans.push(fansList[i]);
      }
    }
  });
  return newList.sort((a, b) => (a.count < b.count ? 1 : -1)).filter(({ count }) => count > 0);
};
export const Member = ({ member }: { member: MemberDetails }) => {
  const navigate = useNavigate();
  const address = useSelector(userSelectors.address);
  const isMobile = useSelector(userSelectors.isMobile);
  const [memberInfo, setMemberInfo] = useState({
    title: "",
    link: "",
  } as MemberInfo);
  const isOwner = address === member.owner;
  // Used solely to update the member card
  const [updateValue, doUpdate] = useState(false);
  const update = () => {
    doUpdate(!updateValue);
  };
  const processedReactions = processReactions(member.reactions?.reactionsList);
  const tabOpen =
    (member.poll && member.poll.commitEndDate * 1000 > Date.now()) ||
    member.applicationExpiry > Date.now() ||
    !member.whitelisted;
  const otherReactions =
    member.reactions &&
    member.reactions.reactionsList.length -
      processedReactions.reduce((p, c) => (c.count > 0 ? p + 1 : p), 0);
  // Connects to S3 to fetch the member metadata
  useEffect(() => {
    fetch(`${getAwsEndpoints().url}/resources/${member.owner}/metadata.json`)
      .then((resp) => resp.json())
      .then((json) => {
        setMemberInfo((info) => {
          return { ...info, ...{ title: json.title, link: json.musicPage } };
        });
      });
  }, []);

  const MusicButton = () => {
    return (
      <Button
        variant="transparent"
        target="_blank"
        rel="noopener"
        style={{ width: 130 }}
        href={memberInfo.link}
      >
        Music page
        <i className="bi bi-music-note" />
      </Button>
    );
  };
  return (
    <div
      className="m-2 position-relative member-card"
      style={{ width: isMobile ? "100%" : "18rem", height: 250 }}
    >
      <Card
        className={
          "overflow-hidden border-0 shadow p-2 w-100" +
          (isOwner ? " bg-pearl" : "")
        }
        style={{
          height: tabOpen ? 200 : 252,
          zIndex: 10,
          transition: "all 0.2s",
        }}
      >
        {/* <div style={{position:"relative",top:-10}} className="w-100"><span className="rounded-circle bg-primary"><i className="bi bi-trophy"/></span></div> */}
        {isOwner && (
          <div
            style={{
              transform: "rotate(-0.1turn) translateX(-28%) translateY(-200%)",
            }}
            className="text-center text-white bg-primary w-100 position-absolute start-0"
          >
            Your entry
          </div>
        )}
        <Card.Body
          className={`d-flex flex-column justify-content-around pt-0 px-0 align-items-center pb-0`}
        >
          <div className="d-flex align-items-center w-100 justify-content-around">
            <div
              className="position-relative"
              role="button"
              onClick={() => navigate(member.data)}
            >
              <i
                style={{ bottom: 0, height: 25, width: 25,zIndex:100 }}
                className="bi bi-list text-primary rounded-circle shadow-sm position-absolute d-flex align-items-center justify-content-center bg-white"
              ></i>
              <ProfilePicture
                size={100}
                className="rounded-circle p-1 border-4 shadow-sm border-white"
                address={member.owner}
              />
            </div>

            {getReactionsAddress() && (
              <div className="d-flex flex-column gap-2">
                <MusicButton />
                {!isOwner && !member.reactions?.fansList.includes(address) && (
                  <div
                    title={
                      address
                        ? undefined
                        : "You need a connected account to perform this action"
                    }
                  >
                    <AwardModal
                      disabled={!address}
                      button={
                        <Button
                          style={{ width: 130 }}
                          disabled={
                            !address ||
                            !!member.reactions?.fansList.includes(address)
                          }
                        >
                          Award <i className="bi bi-award" />
                        </Button>
                      }
                      member={member}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <Card.Title className="mb-0 fw-bold fs-5 mb-0">
            {member.data}
            {!member.whitelisted && (
              <i
                title="This member is currently in probation"
                className="ms-2 text-primary bi bi-clock"
              />
            )}
          </Card.Title>
          {getReactionsAddress() ? (
            <div className="text-muted my-1">
              <div
                key={member.reactions?.reactionsList.length}
                className="d-flex justify-content-end align-items-center"
                role="button"
                onClick={() => navigate(member.data)}
                style={{
                  bottom: 20,
                }}
              >
                {processedReactions
                  .map(({ tag }, index) => {
                    return (
                      <div key={index} className="d-flex">
                        <div
                          style={{
                            height: 25,
                            width: 25,
                            zIndex: 100 - index,
                            marginLeft: index && -6,
                          }}
                          className="d-flex align-items-center justify-content-center rounded-circle bg-primary shadow-sm border border-white border-2"
                        >
                          <i className={`text-white bi bi-${tag[1]}`} />
                        </div>
                      </div>
                    );
                  })}
                <small className="fw-bold text-primary">
                  {!!member.reactions?.reactionsList.length &&
                    !!otherReactions &&
                    "...+" + otherReactions}
                </small>
              </div>
            </div>
          ) : (
            <MusicButton />
          )}
        </Card.Body>
      </Card>
      <div
        className={`${
          tabOpen ? "" : "d-none"
        } position-absolute d-flex flex-column justify-content-end ${
          address ? "bg-primary" : "bg-lightsalmon"
        } rounded-3`}
        style={{ height: "100%", width: "100%", top: 2, zIndex: 0 }}
      >
        {member.poll && member.poll.commitEndDate * 1000 > Date.now() ? (
          <VoteModal member={member} memberInfo={memberInfo} update={update} />
        ) : member.applicationExpiry > Date.now() ? (
          <ChallengeModal
            member={member}
            memberInfo={memberInfo}
            update={update}
          />
        ) : (
          !member.whitelisted && (
            <UpdateModal member={member} memberInfo={memberInfo} />
          )
        )}
      </div>
    </div>
  );
};
export default Member;
