import { Button } from "react-bootstrap";

export const FatalError = ({ error }: { error: Error }) => {
  console.log(error)
  return (
    <div className="mt-3">
      <h1 className="text-primary">Critical error</h1>
      <h4>
        There has been a critical error, probably due to the connection to the
        blockchain provider. <br /> If already connected check wether you
        selected the correct network, and refresh the page.
        <br />
        <br />
        <div className="my-1 d-flex justify-content-center">

        <Button onClick={()=>window.location.reload()}>Refresh <i className="bi bi-arrow-clockwise"/></Button>
        </div>
        <br />
        <div className="text-center fs-5">
          <i>Sorry for the inconvenience</i>
        </div>
      </h4>
      {error && (
        <>
          <h5 className="text-primary mt-5">{error.name}:</h5>
          <main className="text-break">{error.message}</main>
        </>
      )}
    </div>
  );
};
export default FatalError;
