import { useParams, useNavigate } from "react-router-dom";
import { selectors as guildSelectors } from "../store/slices/guild";
import { selectors as userSelectors } from "../store/slices/user";
import { useSelector } from "react-redux";
import { Button, Container } from "react-bootstrap";
import { useEffect, useState } from "react";
//import { tagList } from "../components/modals/Award";
import { processReactions } from "../components/cards/Member";
import { shorten } from "../utils";
import ProfilePicture from "../components/ProfilePicture";
import { getAwsEndpoints } from "../api/environment";
import Award from "../components/modals/Award";

export type MemberInfo = {
  title: string;
  link: string;
};
export const GuildMemberDetails = () => {
  const { memberName } = useParams();
  const navigate = useNavigate();
  const members = useSelector(guildSelectors.members);
  const currentMember = members.find((m) => m.data === memberName);
  const address = useSelector(userSelectors.address);
  const isMobile = useSelector(userSelectors.isMobile);
  const guild = useSelector(guildSelectors.details);
  const processedReactions = processReactions(
    currentMember?.reactions?.reactionsList,
    currentMember?.reactions?.fansList
  );
  const [memberInfo, setMemberInfo] = useState({
    title: "",
    link: "",
  } as MemberInfo);

  const memberNameOrAddress = (address: string | undefined) => {
    if (address === undefined) {
      return "unknown";
    }
    const member = members.find((m) => m.owner === address);
    if (member === undefined) {
      return shorten(address);
    }
    return member.data;
  };

  useEffect(() => {
    fetch(
      `${getAwsEndpoints().url}/resources/${
        currentMember && currentMember.owner
      }/metadata.json`
    )
      .then((resp) => resp.json())
      .then((json) => {
        setMemberInfo((info) => {
          return { ...info, ...{ title: json.title, link: json.musicPage } };
        });
      });
  }, []);
  return (
    <main
      className={`position absolute ${
        isMobile ? "position-absolute" : ""
      } w-100 start-0`}
      style={{
        marginTop: -25,
      }}
    >
      <div
        style={{
          backgroundSize: "cover",
          backgroundImage: isMobile
            ? `url(${getAwsEndpoints().url}/resources/${
                currentMember && currentMember.owner
              }/avatar.png)`
            : undefined,
          backgroundPosition: "0% 30%",
          height: isMobile ? 300 : "auto",
        }}
        className="w-100"
      >
        <div className=" w-100 h-100 d-flex flex-column justify-content-between">
          <div className="d-flex justify-content-end align-items-center m-2">
            {isMobile && (
              <Button
                className=" shadow bg-white"
                variant="light"
                onClick={() => navigate(-1)}
              >
                <i className="bi bi-arrow-left fs-4" />
              </Button>
            )}
          </div>
          <div
            className="p-2"
            style={{
              background: isMobile
                ? "linear-gradient(0deg, rgba(0,0,0,1) 14%, rgba(255,255,255,0) 100%)"
                : undefined,
            }}
          >
            <Container className="d-flex align-items-center p-0">
              {!isMobile && (
                <ProfilePicture address={currentMember?.owner} size={150} />
              )}
              <div>
                <b
                  className={`fs-1 d-flex ${
                    isMobile ? "text-white" : undefined
                  }`}
                >
                  {currentMember && currentMember.data}
                </b>
                <span className="fs-3 text-muted">{guild.name}</span>
              </div>
            </Container>
          </div>
        </div>
      </div>
      <Container className="my-2">
        <div className="d-flex gap-2">
          <Button>
            <span
              className="text-white fw-bold fs-5"
              role="button"
              onClick={() => window.open(memberInfo.link)}
            >
              Music page <i className="bi bi-music-note" />
            </span>
          </Button>
          {currentMember && (
            <Award
              disabled={!address}
              button={
                <Button
                  style={{ width: 130 }}
                  className="fs-5"
                  disabled={
                    !address ||
                    !!currentMember.reactions?.fansList.includes(address)
                  }
                >
                  Award <i className="bi bi-award" />
                </Button>
              }
              member={currentMember}
            />
          )}
        </div>

        <div className="my-3">
          <h3>Awards</h3>
          {processedReactions.length ? (
            <div style={{ maxWidth: 500 }} className="d-flex flex-column gap-1">
              {processedReactions.map(({ tag, fans }, index) => {
                return (
                  <div key={index}>
                    <div className="rounded-3 bg-primary text-white p-1 d-flex gap-1">
                      <i className={`bi bi-${tag[1]}`} />
                      <b>{tag[0]}</b>
                    </div>
                    <i>
                      by {fans.map((f) => memberNameOrAddress(f)).join(", ")}
                    </i>
                  </div>
                );
              })}
            </div>
          ) : (
            <i className="text-muted">No awards yet</i>
          )}
        </div>
      </Container>
    </main>
  );
};
export default GuildMemberDetails;
