import { Button, Modal, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectors as userSelectors } from "../../store/slices/user";
import { actions as guildActions } from "../../store/slices/guild";
import { useState } from "react";
import { MemberDetails } from "../../api/types";

type Props = {
  button: React.ReactNode | React.ReactNode[];
  disabled: boolean;
  member: MemberDetails;
};
export type Tag = [string, string];
export const tagList = [
  ["Stage presence", "emoji-sunglasses"],
  ["Song crafting", "lightbulb"],
  ["Groove making", "heart-pulse"],
  ["Instrument mastery", "lightning"],
  ["Vocal delivery", "music-note-beamed"],
] as Tag[];
export const Award = ({ button, disabled, member }: Props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const isMobile = useSelector(userSelectors.isMobile);
  const [chosenTag, setChosenTag] = useState(-1);
  const [removed, setRemoved] = useState(false);
  const userAddress= useSelector(userSelectors.address)
  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(
      guildActions.willAddReaction({
        address: member.owner,
        reaction: chosenTag,
      })
    );
    setShow(false);
    setRemoved(true);
  };
  const handleShow = (e: any) => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <div className={removed ? "d-none" : ""}>
      <div onClick={disabled ? undefined : handleShow}>{button}</div>
      <Modal show={show} onHide={handleClose} className="">
        <Container className="px-4 py-3">
          <h5 className="fw-bold my-2 text-center">Award member</h5>
          <p>
            Award this member for their incredible performance, raw talent, or
            great personality
          </p>

          {!userAddress && (
            <div className="text-center">
              <small className="text-primary">
                You need a connected account to perform this action
              </small>
            </div>
          )}
          <b>Select one of the following:</b>
          <div className="d-flex flex-wrap my-4 justify-content-around gap-2">
            {tagList.map(([name, icon], index) => {
              const selected = index === chosenTag;
              return (
                <div
                  onClick={
                    selected
                      ? () => setChosenTag(-1)
                      : () => setChosenTag(index)
                  }
                  className={`border rounded-3 border-primary px-2 py-1 ${
                    selected ? "bg-primary text-white" : "text-primary"
                  }`}
                  role="button"
                  style={{ fontSize: 14 }}
                  key={`tag-${index}`}
                >
                  <i className={`bi bi-${icon} fs-5`} /> {name}
                </div>
              );
            })}
          </div>
          <Form onSubmit={handleSubmit}>
            {/* Confirmation buttons */}
            <div
              className={`d-flex ${
                isMobile ? "flex-column gap-2" : "flex-row"
              } justify-content-around mt-3 `}
            >
              <Button
                size="sm"
                type="submit"
                style={{ width: isMobile ? "100%" : 180, height: 40 }}
                disabled={chosenTag < 0}
              >
                <span className="fs-6">
                  AWARD{" "}
                  {chosenTag !== -1 && (
                    <i className={`fs-5 m-1 bi bi-${tagList[chosenTag][1]}`} />
                  )}
                </span>
              </Button>
            </div>
          </Form>
        </Container>
      </Modal>
    </div>
  );
};
export default Award;
