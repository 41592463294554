import { call, put, takeLatest, select } from "redux-saga/effects";
import * as Api from "../../api/ethersApi";
import { ethers } from "ethers";
import { connectTcrContract, connectTokenContract } from "../../api/provider";
import {
  GuildDetails,
  ListingDetails,
  TokenDetails,
} from "../../api/types";
import { selectors as userSelectors } from "../slices/user";
import { actions as guildActions } from "../slices/guild";
import { store } from "../../index";

import {
  actions as explorerActions,
  selectors as explorerSelectors,
  Notification,
} from "../slices/explorer";
import { toDecimals } from "../../utils";

function* pushNotification(action: any): any {
  console.log("pushing notification", action);
  const { type, guildAddress, memberName, ...otherData } =
    action.payload.notification;
  const connectedContract: ethers.Contract = yield call(
    connectTcrContract,
    guildAddress
  );
  const guild: GuildDetails = yield Api.getGuildDetails(connectedContract);
  const notification: Notification = {
    type,
    guildAddress,
    isNew: true,
    data: { guild: guild, ...otherData },
  };

  try {
    const member: ListingDetails = yield Api.getListingDetails(
      connectedContract,
      memberName
    );
    notification.member = member;
  } catch {
    notification.type = "member_removed";
    notification.data = { ...notification.data, memberName };
  }
  // Refresh member on notification
  if (guildAddress === store.getState().guild.address) {
    store.dispatch(guildActions.willUpdateMember(memberName));
  }
  console.log("pushing notification", notification);
  yield put(
    explorerActions.didPushNotification({
      notification: notification,
      transactionHash: action.payload.transactionHash,
    })
  );
}

/** For each of the guild address and sets listeners for their contract events */
function* getGuilds(): any {
  const addresses: string[] = yield select(explorerSelectors.addresses);
  const guilds = [] as GuildDetails[];
  // Stores addresses to avoid subscribing to the same token contract twice
  const tokenAddresses: string[] = [];

  for (const address of addresses) {
    try {
      const connectedGuild = yield call(connectTcrContract, address);
      const guildDetails: GuildDetails = yield call(
        Api.getGuildDetails,
        connectedGuild
      );

      if (guildDetails instanceof Error) {
        throw new Error("Non-existant guild at the specified address");
      }
      guilds.push(guildDetails);
      // Setting guild events
      subscribeGuildEvents(connectedGuild, address);
      // Setting token events

      if (tokenAddresses.includes(guildDetails.tokenAddress)) {
        continue;
      }
      tokenAddresses.push(guildDetails.tokenAddress);
      const connectedToken = yield call(
        connectTokenContract,
        guildDetails.tokenAddress
      );
      const tokenDetails = yield Api.getTokenDetails(connectedToken);
      subscribeTokenEvents(connectedToken, address, guildDetails, tokenDetails);

      // Token Events
    } catch (e) {
      console.warn(e);
      if (e instanceof Error) {
        yield put(
          explorerActions.pushToast({
            toast: {
              title: "Error",
              body: `There has been an error loading the guild contract at address "${address}"`,
              variant: "danger",
            },
            transactionHash: e.message,
          })
        );
      }
    }
  }
  yield put(explorerActions.didGetGuilds(guilds));
}
export function subscribeGuildEvents(
  connectedGuild: ethers.Contract,
  address: string
) {
  Api.setEvent(connectedGuild, "_Application", (...eventData) => {
    if (address === store.getState().guild.address) {
      store.dispatch(
        guildActions.willUpdateMember(Api.hexToString(eventData[0]))
      );
    }
    const txHash = eventData[eventData.length - 1].transactionHash;
    store.dispatch(
      explorerActions.willPushNotification({
        notification: {
          type: "_Application",
          guildAddress: address,
          memberName: eventData[2],
        },
        transactionHash: txHash,
      })
    );
  });
  Api.setEvent(connectedGuild, "_Challenge", (...eventData) => {
    if (address === store.getState().guild.address) {
      store.dispatch(
        guildActions.willUpdateMember(Api.hexToString(eventData[0]))
      );
    }
    const txHash = eventData[eventData.length - 1].transactionHash;
    store.dispatch(
      explorerActions.willPushNotification({
        notification: {
          type: "_Challenge",
          guildAddress: address,
          memberName: Api.hexToString(eventData[0]),
        },
        transactionHash: txHash,
      })
    );
  });
  Api.setEvent(connectedGuild, "_RewardClaimed", (...eventData) => {
    if (userSelectors.address === eventData[2]) {
      store.dispatch(guildActions.willGetRewards());
      const txHash = eventData[eventData.length - 1].transactionHash;

      store.dispatch(
        explorerActions.willPushNotification({
          notification: {
            type: "_RewardClaimed",
            guildAddress: address,
            amount: Number(eventData[1]),
          },
          transactionHash: txHash,
        })
      );
    }
  });
  Api.setEvent(connectedGuild, "_ResolveChallenge", (...eventData) => {
    if (address === store.getState().guild.address) {
      store.dispatch(
        guildActions.willUpdateMember(Api.hexToString(eventData[0]))
      );
    }

    const txHash = eventData[eventData.length - 1].transactionHash;

    store.dispatch(
      explorerActions.willPushNotification({
        notification: {
          type: "_ResolveChallenge",
          guildAddress: address,
          memberName: Api.hexToString(eventData[0]),
        },
        transactionHash: txHash,
      })
    );
  });
  Api.setEvent(connectedGuild, "_UpdateStatus", (...eventData) => {
    if (address === store.getState().guild.address) {
      store.dispatch(
        guildActions.willUpdateMember(Api.hexToString(eventData[0]))
      );
    }
    const txHash = eventData[eventData.length - 1].transactionHash;
    store.dispatch(
      explorerActions.willPushNotification({
        notification: {
          type: "_UpdateStatus",
          guildAddress: address,
          memberName: Api.hexToString(eventData[0]),
        },
        transactionHash: txHash,
      })
    );
  });
}

export function subscribeTokenEvents(
  connectedToken: ethers.Contract,
  address: string,
  guildDetails: GuildDetails,
  tokenDetails: TokenDetails
) {
  console.log("setting token events");

  Api.setEvent(connectedToken, "Transfer", (...eventData) => {
    const [sender, recipient] = eventData;
    const userAddress = store.getState().user.address;
    console.log(userAddress);
    const connectedGuildAddress = store.getState().guild.address;
    const isUserSender = sender === userAddress;
    const isUserRecipient = recipient === userAddress;

    const txHash = eventData[eventData.length - 1].transactionHash;
    if (
      (isUserSender || isUserRecipient) &&
      address === connectedGuildAddress
    ) {
      store.dispatch(guildActions.willGetUserBalance());
      store.dispatch(guildActions.willGetUserAllowance());
    }
    if (isUserSender || isUserRecipient) {
      store.dispatch(
        explorerActions.pushToast({
          toast: {
            title: `Transfer - ${guildDetails.name}`,
            body: `You ${isUserSender ? "sent" : "received"} ${toDecimals(
              eventData[2].toNumber(),
              tokenDetails.decimals
            )} ${tokenDetails.symbol} ${isUserSender ? "to" : "from"} ${
              isUserSender ? recipient : sender
            }`,
          },
          transactionHash: txHash,
        })
      );
    }
  });

  Api.setEvent(connectedToken, "Approval", (...eventData) => {
    const [sender, recipient] = eventData;
    const state = store.getState();
    console.log(
      `Sender: ${sender}, recipient: ${recipient}, user address: ${state.user.address}`
    );
    if (
      (sender === state.user.address || recipient === state.user.address) &&
      address === state.guild.address
    ) {
      console.log("dispatching willgetuserbalance");
      store.dispatch(guildActions.willGetUserBalance());
      store.dispatch(guildActions.willGetUserAllowance());
    }
  });
}
export function* sagas() {
  yield takeLatest(explorerActions.willGetGuilds.type, getGuilds);
  yield takeLatest(explorerActions.willPushNotification.type, pushNotification);
}
