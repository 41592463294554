import { Button, Form, Spinner } from "react-bootstrap";
import { FormInput } from "../components/FormInput";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { upload } from "../api/awsUpload";
import {
  actions as guildActions,
  selectors as guildSelectors,
} from "../store/slices/guild";

import { selectors as userSelectors } from "../store/slices/user";
import { fromDecimals, toDecimals } from "../utils";
type FormData = {
  applicantName: string;
  deposit: number;
  pfp?: File;
  musicPage: string;
};
export const GuildApplyMember = () => {
  const isMobile = useSelector(userSelectors.isMobile);
  const navigate = useNavigate();
  const location = useLocation();
  const guildPath = location.pathname.substring(
    0,
    location.pathname.lastIndexOf("/")
  );
  const dispatch = useDispatch();
  const guild = useSelector(guildSelectors.details);
  const token = useSelector(guildSelectors.token);
  const userAllowance = useSelector(guildSelectors.userAllowance);
  const [isLoading, setIsLoading] = useState(false);
  const data: FormData = {
    applicantName: "",
    deposit: 0,
    musicPage: "",
    pfp: undefined,
  };
  const [formData, setFormData] = useState(data);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(guildActions.willApplyMember({ ...formData }));
    upload({
      json: { musicPage: formData.musicPage },
      image: formData.pfp,
    }).then(() => {
      navigate(guildPath);
    });
  };
  return (
    <main
      className="position-relative"
      style={{ maxWidth: 500, height: "fit-content" }}
    >
      {isLoading && (
        <div
          style={{ backgroundColor: "#FCFCFFcc" }}
          className="flex-column position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
        >
          <h4 className="text-darkgray">Submitting application...</h4>
          <br />
          <Spinner variant="primary" animation="border" />
        </div>
      )}

      <header className="justify-content-between d-flex flex-column mb-3">
        <h1 className="mb-4">Apply new member</h1>
        <small className="text-muted fs-6">
          Minimum deposit: <b>{guild.minDeposit}</b>
        </small>
        <small className="text-muted fs-6">
          Apply stage length: <b>{guild.applyStageLen}</b>
        </small>
        <small className="text-muted fs-6">
          Commit stage length: <b>{guild.commitStageLen}</b>
        </small>
      </header>
      <Form onSubmit={handleSubmit}>
        <div className="mb-5 d-flex flex-column gap-4">
          <FormInput
            title="Name"
            nativeProps={{
              type: "text",
              minLength: 5,
              placeholder: "eg. Lady Gaga",
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, applicantName: e.target.value })
            }
          />
          <FormInput
            title="Music Page"
            nativeProps={{
              type: "url",
              placeholder:
                "eg. https://www.youtube.com/channel/UCT9zcQNlyht7fRlcjmflRSA",
              minLength: 1,
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, musicPage: e.target.value })
            }
          />
          <FormInput
            title="Profile picture"
            nativeProps={{
              type: "file",
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.files) {
                setFormData({ ...formData, pfp: e.target.files[0] });
              }
            }}
          />
          <div>
            <FormInput
              title="Deposit"
              nativeProps={{
                type: "number",
                min: guild.minDeposit,
                max: userAllowance,
                step: toDecimals(1, token.decimals),
                disabled:
                  guild.minDeposit === undefined ||
                  userAllowance < guild.minDeposit,
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormData({
                  ...formData,
                  deposit: fromDecimals(Number(e.target.value), token.decimals),
                })
              }
            />
            {(guild.minDeposit === undefined ||
              userAllowance < guild.minDeposit) && (
              <div>
                <small className="text-primary">
                  {" "}
                  Top-up your allowance to issue a deposit{" "}
                </small>
              </div>
            )}
          </div>
        </div>
        <div
          style={{ height: isMobile ? "auto" : 45 }}
          className={`d-flex ${
            isMobile
              ? "flex-column-reverse gap-2"
              : "flex-row justify-content-around"
          }`}
        >
          <Button
            size="sm"
            style={{
              height: isMobile ? 45 : "100%",
              width: isMobile ? "100%" : 200,
            }}
            variant="transparent"
            role="link"
            onClick={() => navigate(guildPath)}
          >
            <small>CANCEL</small>
          </Button>
          <div
            title={
              userAllowance < guild.minDeposit
                ? `You need at least ${guild.minDeposit} in stake to apply`
                : ""
            }
          >
            <Button
              size="sm"
              style={{
                height: isMobile ? 45 : "100%",
                width: isMobile ? "100%" : 200,
              }}
              type="submit"
              disabled={isMobile ? false : userAllowance < guild.minDeposit}
            >
              <small>APPLY</small>
            </Button>
          </div>
        </div>
      </Form>
    </main>
  );
};
export default GuildApplyMember;
