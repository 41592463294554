import { combineReducers } from "redux";
import { reducer as guildReducer } from "./slices/guild";
import { reducer as userReducer } from "./slices/user";
import { reducer as explorerReducer } from "./slices/explorer";

export const createRootReducer = () =>
  combineReducers({
    user: userReducer,
    guild: guildReducer,
    explorer: explorerReducer,
  });
