import { all } from "redux-saga/effects";
import { sagas as guildSagas } from "./guild";
import { sagas as userSagas } from "./user";
import { sagas as explorerSagas } from "./explorer";

function* runAllSagas() {
  yield all([userSagas(), guildSagas(), explorerSagas()]);
}

export default runAllSagas;
