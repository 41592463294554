import { createSlice } from "@reduxjs/toolkit";
import {
  GuildDetails,
  MemberDetails,
  TokenDetails,
  Challenge,
  Poll,
} from "../../api/types";
import { toDecimals } from "../../utils";

export type Claimable = {
  pollNonce: number;
  challenge: Challenge;
  poll: Poll;
};

// Remove this and line 106
/*
const fakeReward = {
  pollNonce: 0,
  challenge: {
    challenger: 
      "0xf40b6e1d550b9A9C1B948376e28a64B82E3365d7",
      resolved:true,
      stake:4000,
      rewardPool:32200+20000,
      totalTokens:400000
  },
  poll: {
    votesFor: 20000,
    votesAgainst: 32200,
    commitEndDate: 1000000000,
    passed: false,
  },
};
*/
const initialState = {
  address: "",
  details: {} as GuildDetails,
  token: { decimals: 0 } as TokenDetails,
  members: [] as MemberDetails[],
  rewards: [
    /*fakeReward*/
  ] as Claimable[],
  userBalance: -1,
  userAllowance: -1,
  membersLoading: "",
  membersLoaded: "",
  hasUser: false,
  waitingForUserApplicant: false,
};
type State = typeof initialState;
export const guildSlice = createSlice({
  name: "guild",
  initialState,
  reducers: {
    willConnectContracts: (state: State, action) => {
      state.address = "";
      return state;
    },
    setToken: (state: State, action) => {
      state.token = action.payload;
    },
    setHasUser: (state: State, action) => {
      state.hasUser = action.payload;
    },
    challengeApplicant: (state: State, action) => state,
    updateStatus: (state: State, action) => state,
    voteApplicant: (state: State, action) => state,
    willUpdateMember: (state: State, action) => state,
    didUpdateMember: (state: State, action) => {
      const { name, updatedMember } = action.payload;
      //console.log(name, updatedMember);
      const index = state.members.findIndex((member) => {
        return member.data === name;
      });

      console.log("wow", name, updatedMember)
      if (index === -1) {
        // Updated member could not be found, so it could be a new member or an already deleted member
        if (updatedMember) {
          console.log(
            "Updated member could not be found, so it could be a new member"
          );
          state.members.unshift(updatedMember);
          return state;
        } else {
          console.log(
            "requested update for a member which was already deleted"
          );
          return;
        }
      }
      // Otherwise, it's an update
      if (action.payload.updatedMember) {
        console.log("It's an update");
        state.members.splice(index, 1, action.payload.updatedMember);
        return state;
      } else {
        console.log("It must be a member which failed application");
        // else it must be a member which failed application
        state.members.splice(index, 1);
        return state;
      }
    },
    setAddress: (state: State, action) => {
      state.address = action.payload;
    },
    willGetRewards: (state: State) => state,
    didGetRewards: (state: State, action) => {
      state.rewards = action.payload;
    },
    willClaimReward: (state: State, action) => state,
    didClaimReward: (state: State, action) => {
      state.rewards.splice(
        state.rewards.findIndex(
          (reward) => reward.pollNonce === action.payload
        ),
        1
      );
    },
    willGetDetails: (state: State) => state,
    didGetDetails: (state: State, action) => {
      state.details = action.payload;
      state.details.minDeposit = toDecimals(
        state.details.minDeposit,
        state.token.decimals
      );
    },
    willGetMembers: (state: State) => {
      state.membersLoading = state.address;
      return state;
    },
    didGetMembers: (state: State, action) => {
      // Sorts by reaction amount
      state.members = action.payload.members.sort((a:MemberDetails,b:MemberDetails)=>{
        if(!a.reactions || !b.reactions){
          return 0;
        }
        return a.reactions?.fansList.length < b.reactions?.reactionsList.length?1:-1
      });
      state.membersLoading = "";
      state.membersLoaded = action.payload.address;
    },
    willApplyMember: (state: State, action) => state,
    didApplyMember: (state: State) => {
      return state;
    },
    /* Token contract */
    willGetUserBalance: (state: State) => {
      state.userBalance = -1;
      return state;
    },
    didGetUserBalance: (state: State, action) => {
      state.userBalance = toDecimals(action.payload, state.token.decimals);
    },
    willGetUserAllowance: (state: State) => {
      state.userAllowance = -1;
      return state;
    },
    didGetUserAllowance: (state: State, action) => {
      state.userAllowance = toDecimals(action.payload, state.token.decimals);
    },
    willSetUserAllowance: (state: State, action) => {
      state.userAllowance = -1;
      return state;
    },
    didSetUserAllowance: (state: State, action) => {
      state.userAllowance = -1;
      // Still -1 waiting for the confirmation event
      return state;
    },

    /* Reactions contract */
    willGetFans:(state:State, action)=>{
      return state;
    },
    didGetFans:(state:State, action)=>{
      return state;
    },
    willGetReactions:(state:State, action)=>{
      return state;
    },
    didGetReactions:(state:State, action)=>{
      return state;
    },
    willAddReaction:(state:State, action)=>{
      return state;
    }
  },
});

export const selectors = {
  address: (state: { guild: State }) => state.guild.address,
  tokenAddress: (state: { guild: State }) => state.guild.details.tokenAddress,
  details: (state: { guild: State }) => state.guild.details,
  name: (state: { guild: State }) => state.guild.details.name,
  members: (state: { guild: State }) => state.guild.members,
  membersLoading: (state: { guild: State }) => state.guild.membersLoading,
  membersLoaded: (state: { guild: State }) => state.guild.membersLoaded,
  userBalance: (state: { guild: State }) => state.guild.userBalance,
  userAllowance: (state: { guild: State }) => state.guild.userAllowance,
  token: (state: { guild: State }) => state.guild.token,
  rewards: (state: { guild: State }) => state.guild.rewards,
  hasUser: (state: { guild: State }) => state.guild.hasUser,
};

export const { actions, reducer } = guildSlice;
