import ProfilePicture from "../ProfilePicture";
import { useState, useEffect } from "react";
import { MemberDetails } from "../../api/types";
import { MemberInfo } from "./Member";
import { getAwsEndpoints } from "../../api/environment";
import { Button } from "react-bootstrap";

export const MemberMini = ({
  member,
  memberInfo,
}: {
  member: MemberDetails;
  memberInfo?: MemberInfo | undefined;
}) => {
  const [userInfo, setUserInfo] = useState(
    memberInfo ? memberInfo : { title: "", link: "" }
  );

  // Connects to S3 to fetch the member metadata
  useEffect(() => {
    if (memberInfo) {
      return;
    }
    if (!member.owner) {
      return;
    }
    fetch(`${getAwsEndpoints().url}/resources/${member.owner}/metadata.json`)
      .then((resp) => resp.json())
      .then((json) => {
        setUserInfo((info) => {
          return { ...info, ...json };
        });
      });
  }, []);

  return (
    <div className="d-flex me-2">
      <ProfilePicture size={50} address={member.owner} />
      <div className="d-flex flex-column align-items-center justify-content-center">
        <span className="fw-bold fs-5">
          {member.data}
          {!member.whitelisted && (
            <i
              title="This member is currently in probation"
              className="ms-2 text-primary bi bi-clock"
            />
          )}
        </span>
        {userInfo.title ? (
          <span className="text-muted">{userInfo.title}</span>
        ) : (
          <Button
            variant="transparent"
            target="_blank"
            rel="noopener"
            style={{ width: 130 }}
            href={memberInfo?.link}
          >
            Music page
            <i className="bi bi-music-note" />
          </Button>
        )}
      </div>
    </div>
  );
};
export default MemberMini;
