import { Body } from "./Body";
import { Sidebar } from "./Sidebar";
import Toasts from "../components/Toasts";
import { useSelector } from "react-redux";
import {selectors as userSelectors} from "../store/slices/user"
import MobileNavbar from "./MobileNavbar";
import ChangeNetworkModal from "../components/modals/ChangeNetwork";
export const Layout = () => {
  const isMobile = useSelector(userSelectors.isMobile);
  return (
    <div className={`d-flex ${isMobile?"flex-column":"flex-row"} min-vh-100`}>
          {isMobile?<MobileNavbar/>:<Sidebar />}
          <Body />
          <Toasts />
          <ChangeNetworkModal/>
    </div>
  );
};

export default Layout;
