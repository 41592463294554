import { Nav, Navbar, Container, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectors as userSelectors } from "../store/slices/user";
import { selectors as guildSelectors } from "../store/slices/guild";
import ProfilePicture from "../components/ProfilePicture";
import { useState } from "react";
import SetAllowanceModal from "../components/modals/SetAllowance";
// import { shorten } from "../utils";
import { Web3Button } from "../components/Web3Button";
import HintAllowance from "../components/modals/HintAllowance";
import { NotificationsDot } from "./Sidebar";
import TutorialModal from "../components/modals/Tutorial";
export const MobileNavbar = () => {
  const userAddress = useSelector(userSelectors.address);
  const [openedMenu, setOpenedMenu] = useState(false);
  const navigate = useNavigate();
  const userBalance = useSelector(guildSelectors.userBalance);
  const userAllowance = useSelector(guildSelectors.userAllowance);
  const guildAddress = useSelector(guildSelectors.address);
  const token = useSelector(guildSelectors.token);

  return (
    <>
      <div style={{ marginTop: 80 }} />
      <div className=" position-fixed w-100" style={{ zIndex: 300 }}>
        <div className="position-relative">
          <Navbar
            style={{ height: "80px", zIndex: 400 }}
            className="d-flex p-2 shadow-sm bg-white w-100"
          >
            <Container className="flex-row align-content-center justify-content-between h-75">
              <Navbar.Brand
                role="button"
                onClick={() => navigate("/guilds")}
                className="m-0 h-100 d-flex align-items-center"
              >
                <img
                  src="/images/logo_low.png"
                  alt="Guilds logo"
                  className="h-100"
                />
                <i className="bi bi-x opacity-50 fs-5" />{" "}
                <span className=" fw-bold fs-5 opacity-50">ETHMusicians</span>
              </Navbar.Brand>

              <Nav className="d-flex align-items-center">
                <Nav.Item>
                  <span
                    style={{ height: 40 }}
                    className="d-flex flex-row align-items-center m-2"
                  >
                    <Link to={`notifications`}>
                      <Button variant="light">
                        <span className={` fs-5 bi bi-bell position-relative`}>
                          <NotificationsDot />
                        </span>
                      </Button>
                    </Link>
                  </span>
                </Nav.Item>
                <Nav.Item>
                  <span
                    style={{ height: 44, width: 44 }}
                    className={`d-flex justify-content-center align-items-center rounded-circle text-darkergray bg-primary shadow-sm`}
                    role="button"
                    onClick={() => {
                      setOpenedMenu(!openedMenu);
                    }}
                  >
                    <span
                      style={{ height: 44, width: 44 }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      {userAddress ? (
                        <ProfilePicture
                          address={userAddress}
                          size={40}
                          className="fs-5 img-fluid rounded-circle"
                          style={{ zIndex: 600 }}
                        />
                      ) : (
                        <div
                          style={{
                            height: 40,
                            width: 40,
                            zIndex: 600,
                          }}
                          className="bg-primary rounded-circle d-flex align-items-center justify-content-center pulse"
                        >
                          <i className="bi bi-wallet text-white fs-4" />
                        </div>
                      )}

                      <div
                        style={{
                          height: openedMenu ? 40 : 0,
                          width: 44,
                          top: 40,
                          zIndex: 300,
                          transition: "all 0.2s",
                        }}
                        className="bg-primary position-absolute"
                      />
                    </span>
                  </span>
                </Nav.Item>
              </Nav>
            </Container>
          </Navbar>
          <div
            style={{
              width: "100%",
              zIndex: 300,
              right: 0,
              left: 0,
              transition: "transform 0.3s",
              transform: openedMenu ? "translateY(0)" : "translateY(-105%)",
            }}
            className="bg-pearl position-absolute d-flex flex-column align-items-center shadow-sm"
          >
            <div className=" bg-primary p-2 w-100">
              <Container className="d-flex justify-content-around align-content-center fs-5 text-white p-2">
                {userAddress && guildAddress && userBalance !== -1 ? (
                  <div className="flex-column">
                    <div>
                      <i className="bi bi-coin" />{" "}
                      {token.name.length > 8 ? token.symbol : token.name}
                    </div>
                    <div className="d-flex align-items-center justify-content-center text-center gap-1">
                      <b>{userBalance}</b>
                      <b style={{ fontSize: 14 }}>{token.symbol}</b>
                    </div>
                  </div>
                ) : userAddress && guildAddress ? (
                  <Spinner animation="border" size="sm" variant="white" />
                ) : (
                  <div />
                )}
                <div>
                  {!userAddress && (
                    <span>Connect with your preferred WEB3 provider</span>
                  )}
                  <div className="web3-button align-self-center mt-1 d-flex justify-content-end">
                    <Web3Button />
                  </div>
                </div>
              </Container>
              {userAddress && guildAddress && (
                <Container className="d-flex flex-column p-2  border-top border-white">
                  <div className="mb-2">
                    <div className="text-center text-white">
                      <span className="fs-5">Allowance:</span>{" "}
                      {userAllowance >= 0 ? (
                        <span className="fs-5">
                          <b>{userAllowance}</b>{" "}
                          <b style={{ fontSize: 14 }}>{token.symbol}</b>
                        </span>
                      ) : (
                        <Spinner animation="border" size="sm" variant="white" />
                      )}{" "}
                    </div>
                  </div>
                  <div className="d-flex flex-row justify-content-around">
                    <HintAllowance
                      amount={1}
                      button={
                        <Button
                          style={{ minWidth: 100 }}
                          className="fs-5 bg-darksalmon"
                        >
                          +1
                        </Button>
                      }
                    />
                    <HintAllowance
                      amount={10}
                      button={
                        <Button
                          style={{ minWidth: 100 }}
                          className="fs-5 bg-darksalmon"
                        >
                          +10
                        </Button>
                      }
                    />
                    <HintAllowance
                      amount={100}
                      button={
                        <Button
                          style={{ minWidth: 100 }}
                          className="fs-5 bg-darksalmon"
                        >
                          +100
                        </Button>
                      }
                    />
                  </div>
                  <SetAllowanceModal
                    button={
                      <Button className="fs-5 mt-2 w-100">Custom amount</Button>
                    }
                  />
                </Container>
              )}
              {userBalance === 0 && (
                <Container className="d-flex justify-content-end align-content-center fs-5 text-white p-0 border-top border-white p-0">
                  <TutorialModal className="w-100 mt-1" buttonText={`How to get ${token.symbol} tokens`} />
                </Container>
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            zIndex: 200,
            transition: "all 0.3s",
            backgroundColor: openedMenu ? "#fff9" : "#fff0",
            pointerEvents: openedMenu ? "auto" : "none",
          }}
          onClick={() => setOpenedMenu(false)}
          className={`position-fixed w-100 h-100`}
        />
      </div>
    </>
  );
};
export default MobileNavbar;
