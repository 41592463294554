import { useEffect, useState } from "react";
type Props = {
  unixDeadline: number;
  onEnd?: Function;
  className?:string;
};

export const Countdown = ({
  unixDeadline,
  onEnd = () => {},
  className=""
}: Props) => {
  let [timeLeft, setTimeLeft] = useState(unixDeadline - Date.now());

  const formatDate = (millisLeft: number): string => {
    function padTo2Digits(num: number) {
      return num.toString().padStart(2, "0");
    }
    const totalSeconds = millisLeft > 0 ? Math.floor(millisLeft / 1000) : 0;
    const seconds = millisLeft > 0 ? Math.floor(totalSeconds % 60) : 0;
    const minutes = millisLeft > 0 ? Math.floor((totalSeconds / 60) % 60) : 0;
    const hours = millisLeft > 0 ? Math.floor((totalSeconds / 3600) | 0) : 0;
    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
      seconds
    )}`;
  };
  useEffect(() => {
    // Fetches member at the end of the timer to update the client
    if (timeLeft > 0) {
      const interval = setInterval(() => {
        setTimeLeft(unixDeadline - Date.now());
      }, 1000);
      return () => clearInterval(interval);
    } else {
      onEnd();
    }
  }, [timeLeft, unixDeadline, onEnd]);

  return (
      <div className={className}>
        {timeLeft > 0 && <small>{formatDate(timeLeft)}</small>}
      </div>
  );
};
export default Countdown;
