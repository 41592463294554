import { ConnectWallet } from "@thirdweb-dev/react";
import {selectNetwork} from "../../api/environment"
export const Web3Button = () => {
  return <ConnectWallet 
  auth={{
      loginOptions: {chainId:selectNetwork()},
      loginOptional: false,
      loginConfig:{
        onError: (error: string) => console.log(error)
      }
  }}
  accentColor="#F9896B" colorMode="light" />;
};
export default Web3Button;
