import { createSlice } from "@reduxjs/toolkit";
export type GuildsState = {
  [K in keyof typeof initialState]: typeof initialState[K];
};
const initialState = {
  hasInjectedProvider: false,
  web3Provider: false,
  address: "",
  initialized: false,
  fatalError: false as false | Error,
  clientWidth: window.innerWidth,
  mobileBreakPoint: 790,
  needReinitialize:false,
  networkMismatch: false as
    | false
    | { currentChain: number; desiredChain: number },
};
type State = typeof initialState;
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setClientWidth: (state: State, action: { payload: any }) => {
      state.clientWidth = action.payload;
    },
    setAddress: (state: State, action: { payload: any }) => {
      state.address = action.payload;
    },
    setNeedReinitialize: (state: State, action: { payload: boolean }) => {
      state.needReinitialize = action.payload;
    },
    sethasInjectedProvider: (state: State, action: { payload: any }) => {
      state.hasInjectedProvider = action.payload;
    },
    setIsLoggedIn: (state: State, action: { payload: any }) => {
      state.web3Provider = action.payload;
    },
    willSetStatus: (state: State) => {
      state.initialized = false;
    },
    didSetStatus: (
      state: State,
      action: {
        payload: {
          hasInjectedProvider: any;
          web3Provider: any;
          address: any;
          networkMismatch:
            | false
            | { currentChain: number; desiredChain: number };
        };
      }
    ) => {
      const { hasInjectedProvider, web3Provider, address, networkMismatch } =
        action.payload;

      state.hasInjectedProvider = hasInjectedProvider;
      state.web3Provider = web3Provider;
      state.address = address;
      state.networkMismatch = networkMismatch;
      state.initialized = true;
    },
    fatalError: (state: State, action: { payload: any }) => {
      state.fatalError = action.payload;
    },
  },
});

export const selectors = {
  hasInjectedProvider: (state: { user: State }) =>
    state.user.hasInjectedProvider,
  web3Provider: (state: { user: State }) => state.user.web3Provider,
  address: (state: { user: State }) => state.user.address,
  initialized: (state: { user: State }) => state.user.initialized,
  fatalError: (state: { user: State }) => state.user.fatalError,
  clientWidth: (state: { user: State }) => state.user.clientWidth,
  mobileBreakPoint: (state: { user: State }) => state.user.mobileBreakPoint,
  networkMismatch: (state: { user: State }) => state.user.networkMismatch,
  needReinitialize: (state: { user: State }) => state.user.needReinitialize,
  isMobile: (state: { user: State }) =>
    state.user.clientWidth <= state.user.mobileBreakPoint,
};

export const { actions, reducer } = userSlice;
