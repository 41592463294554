import GuildCard from "../components/cards/Guild";
import { useSelector } from "react-redux";
import { selectors as explorerSelectors } from "../store/slices/explorer";
import { selectors as userSelectors } from "../store/slices/user";
import { GuildDetails } from "../api/types";

export const Guilds = () => {
  const guildsAddresses = useSelector(explorerSelectors.addresses);
  const guilds = useSelector(explorerSelectors.guilds);
  const isMobile = useSelector(userSelectors.isMobile);
  return (
    <main>
      <h1>Guilds</h1>
      <section className={`${isMobile ? "" : "col-8"} text-muted mb-4`}>
        The <b className="text-primary">Guilds</b> project was created with the
        aim of promoting the evolution of an "Internet of people", capable of
        responding concretely to the essential needs of each human being,
        including trust, security and inclusion, and to develop tools and
        protocols for the transparent and decentralized management of online
        reputation based on curated lists of companies and professionals in
        every industrial sector.
      </section>
      <h3>Join a guild!</h3>
      <div
        className={`d-flex ${
          isMobile ? "flex-column align-items-center" : "flex-row flex-wrap"
        }`}
      >
        {/* <GuildCard/> */}
        {guilds.length ? (
          guilds.map((guild: GuildDetails, i) => {
            return (
              <GuildCard
                key={`${guild.name}-${i}`}
                name={guild.name}
                guildID={guildsAddresses[i]}
              />
            );
          })
        ) : (
          <h2 className="fs-1 text-gray text-center my-5">No guilds</h2>
        )}
      </div>
    </main>
  );
};
export default Guilds;
