// import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./store";
import { ChainId, ThirdwebProvider } from "@thirdweb-dev/react";
import { selectNetwork, getAwsEndpoints } from "./api/environment";
import API_KEY from "./api/API_KEY.json";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./styles/index.scss";

const activeChainId = selectNetwork();
const getRpcProvider = (chain: number | undefined) => {
  switch (chain) {
    case ChainId.OptimismGoerli:
      return "https://optimism-goerli.infura.io/v3/" + API_KEY.infura;
    case ChainId.Goerli:
      return "wss://goerli.infura.io/ws/v3/" + API_KEY.infura;
    case ChainId.Localhost:
    case undefined:
      return "ws://127.0.0.1:7545";
    case ChainId.Mainnet:
      return "https://mainnet.infura.io/v3/" + API_KEY.infura;
    default:
      return "";
  }
};
const store = configureStore({});
export { store };
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const dAppMeta = {
  name: "Guilds",
  description:
    "The Guilds project was created with the aim of promoting the evolution of an 'Internet of people', capable of responding concretely to the essential needs of each human being, including trust, security and inclusion, and to develop tools and protocols for the transparent and decentralized management of online reputation based on curated lists of companies and professionals in every industrial sector.",
  logoUrl: `${getAwsEndpoints().url}/images/logo_low.png`,
  url: getAwsEndpoints().url,
  isDarkMode: false,
};
root.render(
  <Provider store={store}>
    <ThirdwebProvider
      dAppMeta={dAppMeta}
      desiredChainId={activeChainId}
      supportedChains={[activeChainId]}
      autoConnect={true}
      sdkOptions={
        activeChainId !== ChainId.OptimismGoerli
          ? {
              readonlySettings: {
                chainId: activeChainId,
                rpcUrl: getRpcProvider(activeChainId),
              },
            }
          : {}
      }
      walletConnectors={[
        "injected",
        {
          name: "walletConnect",
          options: {
            infuraId: API_KEY.infura,
            [activeChainId]: getRpcProvider(activeChainId),
          },
        },
        "coinbase",
      ]}
      chainRpc={
        activeChainId !== ChainId.OptimismGoerli
          ? { [activeChainId]: getRpcProvider(activeChainId) }
          : {}
      }
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThirdwebProvider>
  </Provider>
);
reportWebVitals();
