import { Routes, Route, Navigate } from "react-router-dom";

import { GuildProvider } from "./components/GuildProvider";
import Layout from "./layout/Layout";

import NotFound from "./pages/NotFound";
import GuildExplorer from "./pages/GuildExplorer";
import GuildMembers from "./pages/GuildMembers";
import GuildRewards from "./pages/GuildRewards";
import GuildApplyMember from "./pages/GuildApplyMember";
import GuildsMetamask from "./pages/GuildsMetamask";
import Guilds from "./pages/Guilds";
import Notifications from "./pages/Notifications";
import { actions as userActions } from "./store/slices/user";
import {
  actions as guildActions,
  selectors as guildSelectors,
} from "./store/slices/guild";
import { actions as explorerActions } from "./store/slices/explorer";
import { useDispatch, useSelector } from "react-redux";
import { useSDK, useSigner, useChainId } from "@thirdweb-dev/react";
import { useEffect } from "react";
import { initialize, setSigner } from "./api/provider";
import { ENVIRONMENT, addresses } from "./api/environment";
import "./styles/App.scss";
import GuildMemberDetails from "./pages/GuildMemberDetails";
let gotGuilds = false;
export const App = () => {
  const dispatch = useDispatch();
  const sdk = useSDK();
  const signer = useSigner();
  const chainId = useChainId();
  
  const guildAddress = useSelector(guildSelectors.address);
  useEffect(() => {
    // Sets the status every time the user logs in
    if (!sdk) {
      console.log("No sdk")
      return;
    }
    const init = async () => {
      const newStatus = await initialize(sdk, signer, chainId);
      dispatch(userActions.didSetStatus(newStatus));
      if (signer) {
        dispatch(guildActions.willGetUserBalance());
        dispatch(guildActions.willGetUserAllowance());
      }
      if (guildAddress) {
        dispatch(guildActions.willConnectContracts(guildAddress));
      }else{
        console.log("did not reconnect contracts")
      }
      if (!gotGuilds) {
        dispatch(explorerActions.willGetGuilds());
        gotGuilds = true;
      }
    };
    init();
    console.log("signer: " + !!signer);
  }, [signer]);
  window.onresize = () => {
    dispatch(userActions.setClientWidth(window.innerWidth));
  };
  return (
    <Routes>
      {/* Instant redirect to the first guilds of the guildAddresses */}
      <Route index element={<Navigate replace to={`guilds/${addresses[ENVIRONMENT][0]}`} />} />
      <Route path="/" element={<Layout />}>
        <Route path="guilds" element={<Guilds />} />
        <Route path="explore" element={<GuildExplorer />} />
        <Route
          path="guilds/:guildAddress"
          element={<GuildProvider signer={signer} />}
        >
          <Route path=":memberName" element={<GuildMemberDetails />} />
          <Route path="apply" element={<GuildApplyMember />} />
          <Route path="rewards" element={<GuildRewards />} />
          <Route path="" element={<GuildMembers />} />
        </Route>
        <Route path="notifications" element={<Notifications />} />
        <Route path="metamask" element={<GuildsMetamask />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default App;
