import { Card, Container, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectors as userSelectors } from "../../store/slices/user";
// import { selectors as explorerSelectors } from "../../store/slices/explorer";
import { shorten } from "../../utils";
type GuildCardProps = {
  tags?: string[];
  name?: string;
  imageUrl?: string;
  guildID?: string;
};
export const Guild = ({
  tags = [],
  name = "Top 50 Freelance",
  imageUrl = "/images/guild_placeholder.png",
  guildID = "prova1",
}: GuildCardProps) => {
  /*
  const notificationsAmount = useSelector(
    explorerSelectors.notifications
  ).reduce((acc: any, not: any) => {
    return not.isNew && not.guildAddress === guildID ? acc + 1 : acc;
  }, 0);
  */
  const isMobile = useSelector(userSelectors.isMobile);
  const navigate = useNavigate();

  return (
    <Card
      className="border-0 m-2 guild-card shadow-sm"
      style={{ width: isMobile ? "100%" : "18rem", maxWidth: "26rem" }}
      role="button"
      onClick={() => navigate(`/guilds/${guildID}`)}
    >
      <Card.Header className="bg-transparent">
        {tags.length > 0 && (
          <div className="d-flex mb-2">
            {tags.map((tagName) => (
              <Badge
                key={tagName}
                className="rounded-3 text-uppercase bg-primary"
              >
                <small>{tagName}</small>
              </Badge>
            ))}
          </div>
        )}
        <Card.Title className="mb-0 fw-bold">{name}</Card.Title>
        <Card.Text className="text-muted">
          <small>
            Address <b>{shorten(guildID)}</b>
          </small>
        </Card.Text>
      </Card.Header>
      <Card.Body className="p-0">
        <div style={{ height: 200 }}>
          <img
            alt="guild"
            className="rounded-3"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            src={imageUrl}
          />
        </div>
        <Container className="d-flex justify-content-around">
          {/* <Button variant="light">
            <i className="bi bi-hand-thumbs-up" />
          </Button>
          <Button variant="light">
            <i className="bi bi-arrow-90deg-right" />
          </Button>
          <Button
            variant="light"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate("/notifications");
            }}
          >
            <span className="fs-5 bi bi-bell position-relative">
              {notificationsAmount > 0 && (
                <small className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                  {notificationsAmount}
                </small>
              )}
            </span>
          </Button> */}
        </Container>
      </Card.Body>
    </Card>
  );
};
export default Guild;
