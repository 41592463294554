import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";

export const CopyButton = ({
  longString,
  children,
}: {
  longString: string;
  children: React.ReactNode;
  disabled?: boolean;
}) => {
  const renderTooltip = ({ ...props }) => (
    <Tooltip id="button-tooltip" {...props}>
      Copied!
    </Tooltip>
  );
  const [show, setShow] = useState(false);
  const [noClipboard, setNoClipboard] = useState(false);
  const copyFn = async () => {
    if (!navigator.clipboard) {
      setNoClipboard(true);
      return;
    }
    try{
      await navigator.clipboard.writeText(longString);
      setShow(true);
      setTimeout(() => setShow(false), 1000);
    }catch{
      setNoClipboard(true);
    }
  };
  return (
    <OverlayTrigger
      placement="top"
      overlay={renderTooltip}
      show={show}
      trigger="click"
    >
      <span role="button" className={`a-like ${noClipboard?"text-break":""}`} onClick={copyFn}>
        {noClipboard ? longString : children}{" "}
        {!noClipboard && <i className="bi bi-clipboard" />}
      </span>
    </OverlayTrigger>
  );
};
export default CopyButton;
