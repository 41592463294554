import { call, put, takeLatest } from "redux-saga/effects";
import * as Provider from "../../api/provider";
import { actions as userActions } from "../slices/user";

function* setStatus() {
  // const status: {
  //   hasInjectedProvider: boolean;
  //   web3Provider: boolean;
  //   address: string;
  //   networkMismatch: false | { currentChain: Number; desiredChain: Number };
  // } = yield call(Provider.initialize);
  // yield put(userActions.didSetStatus(status));
}
export function* sagas() {
  // yield takeLatest(userActions.willSetStatus.type, setStatus);
  // yield call(setStatus);
}
