import { SearchBar } from "../components/SearchBar";
import GuildCard from "../components/cards/Guild";
import { useSelector } from "react-redux";
import { selectors as explorerSelectors } from "../store/slices/explorer";
import { GuildDetails } from "../api/types";
export const GuildExplorer = () => {
  const guildsAddresses = useSelector(explorerSelectors.addresses);
  const guilds = useSelector(explorerSelectors.guilds);
  return (
    <main>
      <h1>Explore</h1>

      <section className="col-8 text-warning mb-5">
        Yet to be implemented!
      </section>
      <SearchBar className="pb-5" />
      <h4 className="mb-5 fw-bold">Popular Guilds</h4>
      <div className="d-flex flex-row flex-wrap">
        {/* <GuildCard/> */}
        {guilds.map((guild: GuildDetails, i) => {
          return (
            <GuildCard
              key={`${guild.name}-${i}`}
              name={guild.name}
              guildID={guildsAddresses[i]}
            />
          );
        })}
      </div>
    </main>
  );
};
export default GuildExplorer;
