import { InputGroup, Form, Dropdown, Button } from "react-bootstrap";

export const SearchBar = (props: any) => {
  return (
    <div {...props}>
      <div className="d-flex flex-row align-items-stretch gap-3">
        <InputGroup size="lg">
          <InputGroup.Text
            className="border-0 bg-lightgray"
            id="inputGroup-search"
          >
            <small className="bi bi-search m-1"></small>
          </InputGroup.Text>
          <Form.Control
            aria-describedby="inputGroup-search"
            placeholder="Search by name..."
            className="border-0 bg-lightgray fs-6"
          />
        </InputGroup>
        <Dropdown className="d-flex align-items-stretch">
          <Dropdown.Toggle
            className="bg-transparent text-primary border border-primary justify-content-between rounded-3 px-4"
            id="dropdown-guilds"
          >
            <strong className="pe-4 fs-6">Filters</strong>{" "}
            <i className="bi bi-filter-left" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>Title</Dropdown.Item>
            <Dropdown.Item>Author</Dropdown.Item>
            <Dropdown.Item>Members</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Button className="rounded-3">
          <strong className="fs-6 px-3">Search</strong>
        </Button>
      </div>
    </div>
  );
};

export default SearchBar;
