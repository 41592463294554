import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { useSelector, useDispatch } from "react-redux";
import {
  actions as explorerActions,
  selectors as explorerSelectors,
  Toast as ToastInterface,
} from "../store/slices/explorer";
export const Toasts = () => {
  const dispatch = useDispatch();
  const toasts: ToastInterface[] = useSelector(explorerSelectors.toasts);

  return (
    <div
      className="position-fixed end-0 bottom-0"
      style={{
        minHeight: "240px",
        width: "100%",
        pointerEvents: "none",
        zIndex: 1000,
      }}
    >
      <ToastContainer position="bottom-end" className="p-3">
        {toasts.map(({ title, body, show, variant, delay }, index) => {
          return (
            <Toast
              key={`toast-${index}`}
              onClose={() => {
                dispatch(explorerActions.closeToast(index));
              }}
              show={show}
              autohide
              delay={delay}
            >
              <Toast.Header className={`text-${variant} bg-${variant} bg-opacity-25`}>
                <strong className="me-auto">{title}</strong>
              </Toast.Header>
              <Toast.Body >{body}</Toast.Body>
            </Toast>
          );
        })}
      </ToastContainer>
    </div>
  );
};

export default Toasts;
/*

    <div
      aria-live="polite"
      aria-atomic="true"
      className="d-block bg-dark position-fixed end-0 bottom-0"
      style={{ minHeight: "240px", zIndex: 1000 }}
    >
      <ToastContainer position="bottom-end" className="p-3">
        <Toast>
          <Toast.Header>
            <strong className="me-auto">Bootstrap</strong>
            <small className="text-muted">2 seconds ago</small>
          </Toast.Header>
          <Toast.Body>Heads up, toasts will stack automatically</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>


*/
