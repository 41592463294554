import { Nav, Navbar, Container, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectors as explorerSelectors } from "../store/slices/explorer";
import { selectors as userSelectors } from "../store/slices/user";
import ProfilePicture from "../components/ProfilePicture";
import { useEffect } from "react";

export const NotificationsDot = () => {
  const notificationsAmount = useSelector(
    explorerSelectors.notificationsAmount
  );
  const notifications = useSelector(explorerSelectors.notifications);
  useEffect(() => {}, [notifications]);
  return (
    <>
      {notificationsAmount > 0 && (
        <small className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
          {notificationsAmount}
        </small>
      )}
    </>
  );
};

export const Sidebar = () => {
  const userAddress = useSelector(userSelectors.address);
  const currentPath = useLocation().pathname.split("/")[1];

  const navigate = useNavigate();
  const navItems = [
    // { path: "explore", icon: "grid" },
    { path: "guilds", icon: "grid" },
    { path: "notifications", icon: "bell", dot: <NotificationsDot /> },
    /* { path: "settings", icon: "gear" },*/
  ];

  return (
    <>
      <div style={{ marginLeft: 80 }} />
      <Navbar
        style={{ width: "80px", zIndex: 500 }}
        className="d-flex flex-column p-2 shadow-sm vh-100 animated-mount-left position-fixed"
      >
        <Container className="flex-column align-content-center mt-5 justify-content-between h-75">
          <Navbar.Brand
            role="button"
            onClick={() => navigate("/guilds")}
            className="m-0"
          >
            <img
              src="/images/logo_low.png"
              alt="Guilds logo"
              className="img-fluid"
            />
          </Navbar.Brand>

          <Nav className="d-flex flex-column text-center">
            {navItems.map(({ path, icon, dot }) => {
              return (
                <Nav.Item key={path}>
                  <span
                    style={{ height: 40 }}
                    className="d-flex flex-row align-items-center m-2"
                  >
                    <div
                      className={
                        "button-border " +
                        (currentPath === path ? "" : "button-border-closed")
                      }
                    ></div>
                    <Button
                      variant="light"
                      className={
                        currentPath === path ? "selected-nav-item" : ""
                      }
                      onClick={() => navigate("/" + path)}
                    >
                      <span className={` fs-5 bi bi-${icon} position-relative`}>
                        {dot}
                      </span>
                    </Button>
                  </span>
                </Nav.Item>
              );
            })}
          </Nav>

          <Nav className="d-flex flex-column text-center">
            <Nav.Item>
              <span
                style={{ height: 40, width: 40 }}
                className="d-flex flex-row align-items-center m-2 justify-content-center"
              >
                <ProfilePicture
                  address={userAddress}
                  size={36}
                  className="fs-5 img-fluid rounded-circle"
                />
              </span>
            </Nav.Item>
          </Nav>
          <span></span>
        </Container>
      </Navbar>
    </>
  );
};
export default Sidebar;
