import { Breadcrumb } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectors as guildSelectors } from "../store/slices/guild";
export const GuildsBreadcrumb = () => {
  const { guildAddress } = useParams();
  const location = useLocation();
  const paths = location.pathname
    .split("/")
    .slice(1)
    .filter((path) => path.length > 0);
  const name = useSelector(guildSelectors.name);
  const navigate = useNavigate();
  return (
    <Breadcrumb>
      <Breadcrumb.Item
        role="button"
        linkAs={"span"}
        onClick={() => navigate("/guilds")}
      >
        Home
      </Breadcrumb.Item>
      {paths.map((path, index) => {
        let pathRoute = "/" + paths.slice(0, index + 1).join("/");
        let pathF = path;
        if (guildAddress && path === guildAddress) {
          pathF = name !== undefined ? name : " ";
        }
        let pathName = pathF[0].toUpperCase() + pathF.slice(1);
        return index === paths.length - 1 ? (
          <Breadcrumb.Item linkAs={"span"} key={path} active>
            {pathName}
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item
            role="button"
            linkAs={"span"}
            key={path}
            onClick={() => navigate(pathRoute)}
          >
            {pathF[0].toUpperCase() + pathF.slice(1)}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};
export default GuildsBreadcrumb;
