import { all, call, put, takeLatest, select } from "redux-saga/effects";
import * as Api from "../../api/ethersApi";
// import * as Provider from "../../api/provider";
import {
  GuildDetails,
  MemberDetails,
  Poll,
  Challenge,
} from "../../api/types";
import {
  actions as guildActions,
  selectors as guildSelectors,
  Claimable,
} from "../slices/guild";
import {
  actions as userActions,
  selectors as userSelectors,
} from "../slices/user";
import { actions as explorerActions } from "../slices/explorer";
import {
  connectedGuild,
  setConnectedGuild,
  connectedToken,
  setConnectedToken,
  connectedReacts,
  signer,
  setConnectedReacts,
} from "../../api/provider";
import { getReactionsAddress } from "../../api/environment";

/** Connects to a guild's TCR and its respective token contract (used when user is in the guild page)*/
function* connectContracts(action: any) {
  console.log("Connecting contracts");
  const address = action.payload;
  setConnectedGuild(address);
  const reactionAddress = getReactionsAddress();
  if (reactionAddress) {
    setConnectedReacts(reactionAddress);
  }
  const details: GuildDetails | Error = yield Api.getGuildDetails(
    connectedGuild
  );
  if (details instanceof Error) {
    yield put(guildActions.setAddress(address));
    yield put(userActions.fatalError(details));
    return;
  }
  setConnectedToken(details.tokenAddress);
  // Error here
  // const guildBalance: number = yield Api.getBalanceOf(connectedToken, address);
  const tokenDetails: object = yield call(Api.getTokenDetails, connectedToken);
  yield put(guildActions.setToken(tokenDetails));

  yield put(guildActions.setAddress(address));
  yield put(guildActions.willGetDetails());
  yield put(guildActions.willGetMembers());
  const hasWeb3Provider: boolean = yield select(userSelectors.web3Provider);
  yield put(guildActions.setHasUser(false));
  if (hasWeb3Provider) {
    yield put(guildActions.willGetUserBalance());
    yield put(guildActions.willGetUserAllowance());
    yield call(getRewards);
  }
}

function* getRewards() {
  const rewards = [] as Claimable[];
  const pollNonce: number = yield Api.getPollNonce(connectedGuild);
  for (let i = 1; i <= pollNonce; i++) {
    const eligible: boolean = yield Api.canClaim(connectedGuild, i);
    if (!eligible) {
      continue;
    }
    const poll: Poll = yield Api.getPoll(connectedGuild, i);
    const challenge: Challenge = yield Api.getChallenge(connectedGuild, i);

    rewards.push({ poll, challenge, pollNonce: i });
  }
  yield put(guildActions.didGetRewards(rewards));
}

function* claimReward(action: any) {
  const { pollNonce } = action.payload;
  yield call(Api.claimRewards, connectedGuild, pollNonce);
  yield put(guildActions.didClaimReward(pollNonce));
}

function* getDetails() {
  const details: GuildDetails = yield call(Api.getGuildDetails, connectedGuild);
  if (details instanceof Error) {
    yield put(userActions.fatalError(details));
    return;
  }
  yield put(guildActions.didGetDetails(details));
}

function* challengeApplicant(action: any) {
  const { name, amount } = action.payload;
  yield call(Api.challenge, connectedGuild, name, amount);
}
function* updateStatus(action: any) {
  const name = action.payload;
  yield call(Api.updateStatus, connectedGuild, name);
}
function* voteApplicant(action: any) {
  const { name, amount, vote } = action.payload;
  yield call(Api.vote, connectedGuild, name, amount, vote);
}

function* getReactions(memberOwner: string) {
  const reactions = {
    fansList: [] as number[],
    reactionsList: [] as number[],
  };

  // Gets the amount of awards the member has received
  const reactionsNo: number = yield Api.noOfReactions(
    connectedReacts,
    memberOwner
  );

  // Creates an array of indexes (from 0 to reactionsNo)
  const reactionsArr = [...Array(reactionsNo).keys()];

  // For each index calls the reactions contract and asks
  // for the member fan at index N
  reactions.fansList = yield all(
    reactionsArr.map((n) => call(Api.getFan, connectedReacts, memberOwner, n))
  );

  // Same, but for reactions
  reactions.reactionsList = yield all(
    reactionsArr.map((n) =>
      call(Api.getReaction, connectedReacts, memberOwner, n)
    )
  );

  return reactions;
}

function* getMemberDetails(memberName: string): any {
  if (!memberName.length) {
    console.log("not existant");
    return;
  }
  const memberDetails: MemberDetails = yield Api.getListingDetails(
    connectedGuild,
    memberName
  );

  const tasks: any = getReactionsAddress()
    ? [call(getReactions, memberDetails.owner)]
    : [];

  if (memberDetails.challengeId) {
    tasks.push(
      call(Api.getChallenge, connectedGuild, memberDetails.challengeId)
    );
    tasks.push(call(Api.getPoll, connectedGuild, memberDetails.challengeId));
  }
  const [reactions, challenge, poll] = yield all(tasks);
  console.log(memberDetails.owner);
  console.log(reactions);
  memberDetails.reactions = reactions;

  if (memberDetails.challengeId) {
    memberDetails.challenge = challenge;
    memberDetails.poll = poll;
  }

  // Checks wether the user already owns one of the listings
  const userAddress: string = yield select(userSelectors.address);
  if (memberDetails.owner === userAddress) {
    yield put(guildActions.setHasUser(true));
  }
  return memberDetails;
}
function* getMembers() {
  console.log("Fetching members");
  let membersNames: string[] = yield call(Api.getListings, connectedGuild);
  membersNames = membersNames.filter((x) => x.length > 0);

  const members: MemberDetails[] = yield all(
    membersNames.map((name) => call(getMemberDetails, name))
  );

  yield put(
    guildActions.didGetMembers({ members, address: connectedGuild.address })
  );
}
function* updateMember(action: any) {
  const connected = select(guildSelectors.address);
  if (!connected) {
    return;
  }
  const memberName = action.payload;
  console.log("Trying to update " + memberName);
  let updatedMember: MemberDetails | undefined;
  // First, check if the member name is in the membersNames array
  const membersNames: string[] = yield call(Api.getListings, connectedGuild);

  if (membersNames.includes(memberName)) {
    updatedMember = yield call(getMemberDetails, memberName);
  }
  yield put(guildActions.didUpdateMember({ name: memberName, updatedMember }));
}
function* applyMember(action: any) {
  try {
    const { applicantName, deposit } = action.payload;
    if (!signer) {
      yield put(guildActions.didApplyMember());
      return;
    }
    const address: string = yield signer.getAddress();
    console.log(address);
    yield call(Api.propose, connectedGuild, applicantName, deposit, address);
    yield put(guildActions.didApplyMember());
    // Pushes toast
    const guildName: string = yield select(guildSelectors.name);
    yield put(
      explorerActions.pushToast({
        toast: {
          title: guildName,
          body: "Your member application has been submitted, the new member will be visible within 5 to 20 seconds",
        },
        transactionHash: "" + Math.random(),
      })
    );
  } catch (e) {
    console.warn(e);
    // In case of failed applcation
    if (e instanceof Error) {
      yield put(
        explorerActions.pushToast({
          toast: {
            title: "Error",
            body: "Your member application has failed, please try again",
          },
          transactionHash: e.message,
        })
      );
    }
  }
}
function* getBalanceOf(action: any) {
  const { address } = action.payload;
  if (!address || !connectedGuild) {
    return -1;
  }
  const balance: number = yield call(Api.getBalanceOf, connectedToken, address);
  const amount = parseInt(balance.toString());
  return amount;
}
function* getUserBalance() {
  const address: string = yield select(userSelectors.address);
  const userBalance: number = yield call(getBalanceOf, {
    payload: { address },
  });
  yield put(guildActions.didGetUserBalance(userBalance));
}
function* getAllowanceOf(action: any) {
  const { address } = action.payload;
  if (!address || !connectedGuild) {
    return -1;
  }
  const allowance: number = yield call(
    Api.getAllowanceOf,
    connectedToken,
    address,
    connectedGuild.address
  );

  const amount = parseInt(allowance.toString());
  return amount;
}
function* getUserAllowance() {
  const address: string = yield select(userSelectors.address);
  const userAllowance: number = yield call(getAllowanceOf, {
    payload: { address },
  });
  // console.log("getting user allowance", userAllowance)
  yield put(guildActions.didGetUserAllowance(userAllowance));
}

export function* setAllowance(action: any): any {
  const { amount } = action.payload;
  yield call(Api.addToStake, connectedToken, connectedGuild, amount);
  yield put(guildActions.didSetUserAllowance(amount));
}

/* REACTIONS SAGAS */
export function* addReaction(action: any): any {
  const { address, reaction } = action.payload;
  yield call(Api.addReaction, connectedReacts, address, reaction);
  console.log("Done1");
}

export function* sagas() {
  yield takeLatest(guildActions.willConnectContracts.type, connectContracts);
  yield takeLatest(guildActions.willGetDetails.type, getDetails);
  yield takeLatest(guildActions.willGetMembers.type, getMembers);
  yield takeLatest(guildActions.willApplyMember.type, applyMember);
  yield takeLatest(guildActions.willGetUserBalance.type, getUserBalance);
  yield takeLatest(guildActions.willGetUserAllowance.type, getUserAllowance);
  yield takeLatest(guildActions.willSetUserAllowance.type, setAllowance);
  yield takeLatest(guildActions.challengeApplicant.type, challengeApplicant);
  yield takeLatest(guildActions.updateStatus.type, updateStatus);
  yield takeLatest(guildActions.voteApplicant.type, voteApplicant);
  yield takeLatest(guildActions.willClaimReward.type, claimReward);
  yield takeLatest(guildActions.willGetRewards.type, getRewards);
  yield takeLatest(guildActions.willUpdateMember.type, updateMember);
  yield takeLatest(guildActions.willAddReaction.type, addReaction);
}
