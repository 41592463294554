import {
  selectors as guildSelectors,
  actions as guildActions,
  Claimable,
} from "../store/slices/guild";
import { selectors as userSelectors } from "../store/slices/user";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import Reward from "../components/cards/Reward";
export const GuildRewards = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useSelector(userSelectors.isMobile);
  const rewards: Claimable[] = useSelector(guildSelectors.rewards);
  return (
    <>
      {isMobile && (
        <div className="d-flex justify-content-end">
          <Button variant="light" onClick={() => navigate(-1)}>
            <i className="bi bi-x-circle fs-1" />
          </Button>
        </div>
      )}
      <main>
        <Container>
          <div className="d-flex flex-column">
            {rewards.length ? (
              rewards
                .map((reward: Claimable, i) => {
                  return (
                    <Reward
                      key={`reward-${i}`}
                      votesFor={reward.poll.votesFor}
                      votesAgainst={reward.poll.votesAgainst}
                      side={reward.poll.votesFor >= reward.poll.votesAgainst}
                      date={reward.poll.commitEndDate}
                      challAddress={reward.challenge.challenger}
                      claimAction={() => {
                        dispatch(
                          guildActions.willClaimReward({
                            pollNonce: reward.pollNonce,
                            index: i,
                          })
                        );
                      }}
                    />
                  );
                })
                .reverse()
            ) : (
              <h2 className="fs-1 text-gray text-center my-5">
                You have no rewards to claim
              </h2>
            )}
          </div>
        </Container>
      </main>
    </>
  );
};
export default GuildRewards;
